import React from "react";
import qs from "query-string";
import {withRouter} from "react-router-dom";

/**
 * `withQs` HOC wrapper
 *
 * Makes query string object available on a component as `qs`.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withQs = Component => {

	/**
	 * `ComponentWithQs` inner wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwqs = class ComponentWithQs extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return <Component {...this.props} qs={qs.parse(this.props.location.search)} />;
		}

	};

	return withRouter(cwqs);

};

export default withQs;
