/**
 * Price utility methods
 *
 * @package HOPS
 * @subpackage Memberships
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Price {

	/**
	 * Get a price UI string from a numeric value.
	 *
	 * @param {Integer} pence
	 * @param {Boolean} forcePence optional Force pence to display
	 * @return {String}
	 */
	static getUiString(price, forcePence=true) {
		price = (price / 100)?.toFixed(2);
		const [pounds, pence] = (price?.toString()?.split(".") || []);
		const valid = (![null, undefined].includes(price));
		let pennies = pence;
		if (pennies) pennies = `.${pennies.padEnd(2, "0")}`;
		else if (valid && forcePence) pennies = `.00`;
		return `£${pounds}${(pennies || "")}`;
	}

}

export default Price;
