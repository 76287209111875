import React from "react";
import Container from "Components/Container.js";
import OrderCompleteBanner from "Checkout/OrderCompleteBanner.js";
import withAuth from "Hoc/withAuth.js";
import {connect} from "react-redux";

/**
 * UI banners host
 *
 * Displays the global banner messages.
 * 
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBanners extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Banners
		 *
		 * @type {Array}
		 */
		banners: this.banners

	};


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		const chr = (prevProps.checkoutRedirect !== this.props.checkoutRedirect);
		if (chr) this.setState({banners: this.banners});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (!this.state.banners?.length) return null;
		return (this.state.banners ? this.renderBanners() : null);
	}


	/**
	 * Render banners.
	 * 
	 * @return {ReactNode}
	 */
	renderBanners() {
		return (
			<Container mb={2} mt={-4} style={this.constructor.containerStyles}>
				{
					this.state.banners.map((Banner, key) => (
						<Banner key={key} />
					))
				}
			</Container>
		);
	}


	/**
	 * Get banners to render.
	 *
	 * @return {Array}
	 */
	get banners() {

		const banners = [];

		if (this.props.checkoutRedirect) {
			banners.push(OrderCompleteBanner);
		}

		return banners;

	}


	/**
	 * Container styles.
	 * 
	 * @type {Object}
	 */
	static containerStyles = {position: "sticky", top: "9rem", width: "100%", zIndex: 1000};

}

export default connect(({checkoutRedirect}) => ({checkoutRedirect}))(withAuth(UiBanners));
