import React from "react";

/**
 * `AsyncStatefulComponent`
 *
 * Component which avoids state updates when unmounted.
 * 
 * @package HOPS
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AsyncStatefulComponent extends React.PureComponent {

	/**
	 * Mounted?
	 *
	 * @type {Boolean}
	 */
	mounted = false;


	/**
	 * Mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.mounted = true;
	}


	/**
	 * Unmounted.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		this.mounted = false;
	}


	/**
	 * Update the state.
	 *
	 * The call is ignored when unmounted.
	 *
	 * @param {Object} state
	 * @param {Function} callback optional
	 * @return {void}
	 */
	setState(state, callback=null) {
		if (this.mounted) super.setState(state, callback);
	}

}

export default AsyncStatefulComponent;
