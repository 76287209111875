import React from "react";
import ButtonBase from "./ButtonBase.js";
import IconButton from "./IconButton.js";
import Navigator from "App/Navigator.js";
import ReactHelper from "Helpers/React.js";

/**
 * Button
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Button extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick(e) {

		e.preventDefault();
		e.stopPropagation();

		if (this.props.uri) {
			Navigator.navigate(this.props.uri);
		}

		else if (this.props.onClick) {
			this.props.onClick(this.props.value, this.props.name);
		}

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		const com = (!this.props.icon ? ButtonBase : IconButton);
		return ReactHelper.render(com, {...this.props, onClick: (((this.props.uri || this.props.onClick)) ? this.handleClick : undefined)});
	}

}

export default Button;
