import React from "react";
import Flex from "Components/Flex.js";
import String from "./String.js";
import scss from "./Banner.module.scss";
import {Alert, AlertTitle} from "@material-ui/lab";

/**
 * Banner component
 *
 * A banner using Material's `Alert`.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Banner extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert classes={this.constructor.classes} severity={this.props.severity}>
				{(this.props.title && this.renderTitle())}
				<Flex
					gap={this.props.gap}>
					{(this.props.str && <String gap={0.75} str={this.props.str} />)}
					{(this.props.children && this.renderChildren())}
				</Flex>
			</Alert>
		);
	}


	/**
	 * Render children.
	 * 
	 * @return {ReactNode}
	 */
	renderChildren() {
		return (
			<Flex gap={this.props.gap}>
				{this.props.children}
			</Flex>
		);
	}


	/**
	 * Render the title.
	 * 
	 * @return {ReactNode}
	 */
	renderTitle() {
		return (
			<AlertTitle classes={this.constructor.titleClasses}>
				{this.props.title}
			</AlertTitle>
		);
	}


	/**
	 * `Alert` classes.
	 * 
	 * @type {Object}
	 */
	static classes = {icon: scss.icon};

	/**
	 * `AlertTitle` classes.
	 * 
	 * @type {Object}
	 */
	static titleClasses = {root: scss.title};

}

export default Banner;
