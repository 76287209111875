import React from "react";
import Container from "Components/Container.js";
import {CircularProgress} from "@material-ui/core";

const LOADER_ABSOLUTELY_CENTRED_STYLES = {
	position: "absolute",
	left: "50%",
	top: "50%",
	transform: "translateX(-50%) translateY(-50%)"
};

export default ({absolute, align, className, color, justify, size}) => (
	<Container
		alignContent="center"
		alignItems="center"
		alignSelf={(align || "center")}
		className={className}
		justifyContent="center"
		justifyItems="center"
		justifySelf={(justify || "center")}
		style={(absolute ? LOADER_ABSOLUTELY_CENTRED_STYLES : null)}>
		<CircularProgress color={color} disableShrink={true} size={size} />
	</Container>
);
