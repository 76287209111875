/**
 * CSS helper
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Css {

	/**
	 * Set a custom property value.
	 * 
	 * @param {String} prop
	 * @param {String} value
	 * @return {void}
	 */
	static setCustomProperty(prop, value) {
		document.documentElement.style.setProperty(`--${prop}`, value);
	}

}

export default Css;
