import React from "react";
import {connect} from "react-redux";

/**
 * `withStrings` HOC wrapper
 *
 * Makes the stateful branded strings available on a component as `strings`.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withStrings = Component => {

	/**
	 * `ComponentWithStrings` inner wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cws = class ComponentWithStrings extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					strings={this.props.strings} />
			);
		}

	};

	return connect(({strings}) => ({strings}))(cws);

};

export default withStrings;
