import React from "react";
import Helmet from "Components/Helmet.js";
import LoginView from "Login/LoginView.js";
import withAuth from "Hoc/withAuth.js";

/**
 * Route wrapper
 *
 * Wraps a route component so it can receive its own `UiRoute` object.
 *
 * @param {Object} route Route object (`UiRoutes`)
 * @return {ReactComponent} Wrapped route component
 */
const wrapRoute = route => {

	/**
	 * Route wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const wrap = class WrappedRouteComponent extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode|null}
		 */
		render() {
			return (
				<React.Fragment>
					<Helmet
						description={route.description}
						title={route.title} />
					{this.renderMain()}
				</React.Fragment>
			);
		}


		/**
		 * Render login screen.
		 * 
		 * @return {ReactNode}
		 */
		renderAuth() {
			return (
				<LoginView
					{...this.props}
					header={route.authHeader}
					caption={route.authCaption} />
			);
		}


		/**
		 * Render the main content.
		 * 
		 * @return {ReactNode|null}
		 */
		renderMain() {

			const authed = (!route.auth || this.props.authed);
			const Component = route.component;

			if (!authed) return this.renderAuth();
			else return <Component {...this.props} />;

		}

	};

	return withAuth(wrap);

};

export default wrapRoute;
