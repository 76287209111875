import Routes from "Resources/Routes.json";
import {F12Icon, MembershipsIcon, TicketsIcon, SharesIcon} from "Resources/Icons.js";

export default [
	{
		name: "Tickets",
		label: "Tickets",
		caption: () => "Purchase tickets for travel, events and experiences.",
		icon: TicketsIcon,
		uri: Routes.tickets
	},
	{
		name: "Memberships",
		label: "Memberships",
		caption: org => `Purchase ${org.NameShort} Membership.`,
		icon: MembershipsIcon,
		uri: Routes.memberships
	},
	{
		name: "Shares",
		label: "Shares",
		caption: org => `Purchase shares in a ${org.NameShort} company.`,
		icon: SharesIcon,
		uri: Routes.shares
	},
	{
		name: "F12",
		label: "Developer tools",
		caption: () => "App developer tools.",
		icon: F12Icon,
		uri: "/?f12=true"
	}
];
