import React from "react";
import Features from "Resources/Features.js";
import {connect} from "react-redux";

/**
 * `withFeatures` HOC wrapper
 *
 * Makes the active features list available on a component as `features`.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withFeatures = Component => {

	/**
	 * `ComponentWithFeatures` inner wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwf = class ComponentWithFeatures extends React.PureComponent {

		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					features={this.enabledFeatureObjects}
					featuresAvailable={this.availableFeatureObjects}
					featuresForced={this.props.featuresForced}
					isFeatureEnabled={this.isFeatureEnabled}
					isLiveFeature={this.isLiveFeature}
					isPreviewFeature={this.isPreviewFeature} />
			);
		}

		/**
		 * Get all available feature objects.
		 * 
		 * @return {Array}
		 */
		get availableFeatureObjects() {
			return Features.filter(({name}) => (this.isFeatureEnabled(name) || this.isPreviewFeature(name)));
		}

		/**
		 * Get all enabled feature objects.
		 * 
		 * @return {Array}
		 */
		get enabledFeatureObjects() {
			return Features.filter(f => this.isFeatureEnabled(f.name));
		}

		/**
		 * Get whether we have a given feature by name.
		 * 
		 * @param {String} feature
		 * @return {Boolean}
		 */
		isFeatureEnabled = feature => [...this.props.features, ...this.props.featuresForced].includes(feature);

		/**
		 * Get whether a given feature is a live feature by name.
		 *
		 * @param {String} feature
		 * @return {Boolean}
		 */
		isLiveFeature = feature => this.props.features.includes(feature);

		/**
		 * Get whether a given feature is a preview feature by name.
		 * 
		 * @param {String} feature
		 * @return {Boolean}
		 */
		isPreviewFeature = feature => (this.props.featuresAvailable[feature] === false);

	};

	return connect(({features, featuresAvailable, featuresForced}) => ({features, featuresAvailable, featuresForced}))(cwf);

};

export default withFeatures;
