import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import dCheckoutBasketDiscounts from "Dispatchers/dCheckoutBasketDiscounts.js";
import dCheckoutBasketLoading from "Dispatchers/dCheckoutBasketLoading.js";
import snack from "App/SnackbarHost.js";
import withCheckoutBasket from "Hoc/withCheckoutBasket.js";
import {Flex, IconButton, String} from "@heron-web/material";
import {CheckoutDiscountService} from "@hps/hops-sdk-js";
import {useCallback} from "react";

export default withCheckoutBasket(props => {

	const {checkoutBasketDiscounts, checkoutBasketId, checkoutBasketLoading} = props;
	if (!checkoutBasketDiscounts?.length) return null;


	/**
	 * Get the unique discount codes in the basket
	 */
	const uniqueCodes = checkoutBasketDiscounts.map(discount => {
		return discount.Discount.Code;
	}).filter((v, i, a) => {
		return (a.indexOf(v) === i);
	});


	/**
	 * Remove a discount code from the basket.
	 *
	 * @async
	 * @param {String} code
	 * @return {void}
	 */
	const handleRemoveCode = useCallback(async code => {

		const discountClaimsToRemove = checkoutBasketDiscounts.filter(c => {
			return (c.Discount.Code === code);
		}).map(c => {
			return c.Discount.Claim;
		});

		dCheckoutBasketLoading(true);

		try {

			const remainingDiscounts = await CheckoutDiscountService.removeDiscountCode(
				checkoutBasketId,
				discountClaimsToRemove
			);

			dCheckoutBasketDiscounts((remainingDiscounts || []));
			snack(`Discount code ${code} removed.`, "success");

		}
		catch (e) {
			snack(e);
		}

		dCheckoutBasketLoading(false);

	}, [checkoutBasketDiscounts, checkoutBasketId]);


	/**
	 * Render!
	 */
	return (
		<Flex>
			{
				uniqueCodes.map((code, key) => (
					<Flex
						alignItems="center"
						columnar={true}
						key={key}>
						<IconButton
							color="secondary"
							disabled={checkoutBasketLoading}
							icon={DeleteIcon}
							onClick={() => handleRemoveCode(code)}
							size="small" />
						<String
							noFlex={true}
							str={code} />
					</Flex>
				))
			}
		</Flex>
	);

});
