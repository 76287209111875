import AppService from "Services/AppService.js";
import dFeatures from "Dispatchers/dFeatures.js";
import dFeaturesAvailable from "Dispatchers/dFeaturesAvailable.js";
import dRegistration from "Dispatchers/dRegistration.js";

export default () => {
	return new Promise((resolve, reject) => {
		AppService.getRegistration().then(reg => {
			dRegistration(reg);
			dFeaturesAvailable(reg.Features);
			dFeatures(Object.keys(reg.Features).filter(f => reg.Features[f]));
			resolve();
		}).catch(e => {
			reject(e);
		});
	});
};
