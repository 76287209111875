import React from "react";
import Navigator from "App/Navigator.js";
import Str from "Components/String.js";
import scss from "./Link.module.scss";
import {Link as MuiLink} from "@material-ui/core";

/**
 * Link component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Link extends React.PureComponent {

	/**
	 * Clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick = e => {
		if (this.props.disabled) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}
		else if (this.props.onClick) {
			this.props.onClick();
		}
		else if (this.props.uri && this.props.uri.includes("://")) {
			window.open(this.props.uri, "_target=blank");
		}
		else if (this.props.uri) {
			e.preventDefault();
			Navigator.navigate(this.props.uri);
		}
	};


	/**
	 * Key pressed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKeyPress = e => {
		if ([13, 32].includes(e.which)) {
			this.handleClick(e);
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiLink
				classes={this.classes}
				className={`${(this.props.inline ? scss.inline : "")} ${(this.props.disabled ? "disabled" : "")} ${(this.props.className || "")}`.trim()}
				color={this.props.color}
				component={this.props.component}
				onClick={this.handleClick}
				onKeyDown={this.handleKeyPress}
				src={this.props.src}
				tabIndex={(this.props.tabIndex || "0")}
				title={this.props.title}>
				<Str
					bold={this.props.bold}
					centre={this.props.centre}
					color={this.props.color}
					component={this.props.strComponent}
					str={this.label}
					variant={this.props.variant} />
			</MuiLink>
		);
	}


	/**
	 * Get our classes.
	 * 
	 * @return {Object}
	 */
	get classes() {
		return {...this.props.classes, root: scss.root};
	}


	/**
	 * Get the label.
	 * 
	 * @return {String}
	 */
	get label() {
		const lbl = this.props.label;
		if (this.props.endArrow) return `${lbl} ${String.fromCharCode(8594)}`;
		else if (this.props.startArrow) return `${String.fromCharCode(8592)} ${lbl}`;
		else return lbl;
	}

}

export default Link;
