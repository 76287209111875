import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import AuthService from "Services/AuthService.js";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import EmailAddressInput from "Inputs/EmailAddressInput.js";
import Link from "Components/Link.js";
import LoginForeignErrorDialog from "./LoginForeignErrorDialog.js";
import LoginFormPasswordInput from "./LoginFormPasswordInput.js";
import LoginFormUsernameInput from "./LoginFormUsernameInput.js";
import PoweredByHops from "Components/PoweredByHops.js";
import PwcForm from "./PwcForm.js";
import String from "Components/String.js";
import dAuth from "Dispatchers/dAuth.js";
import withMobile from "Hoc/withMobile.js";
import withSnack from "Hoc/withSnack.js";

/**
 * Login form
 *
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginForm extends AsyncStatefulComponent {

	/**
	 * Username input ref
	 * 
	 * Used to enable programmatic focusing.
	 * 
	 * @type {ReactRef}
	 */
	usernameInput = React.createRef();

	/**
	 * Password input ref
	 * 
	 * Used to enable programmatic focusing.
	 * 
	 * @type {ReactRef}
	 */
	passwordInput = React.createRef();

	/**
	 * Form
	 * 
	 * @type {ReactRef}
	 */
	form = React.createRef();

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Username
		 *
		 * @type {String}
		 */
		username: "",

		/**
		 * Password
		 *
		 * @type {String}
		 */
		password: "",

		/**
		 * Email address (password reset)
		 * 
		 * @type {String}
		 */
		emailPwc: "",

		/**
		 * Loading?
		 *
		 * @type {Boolean}
		 */
		loading: false,

		/**
		 * Error code
		 * 
		 * @type {Integer|null}
		 */
		error: null,

		/**
		 * Authenticated to foreign railway
		 * 
		 * @type {Boolean}
		 */
		foreign: false,

		/**
		 * Mode
		 *
		 * Either "login", "pwc" or "pwcr" (password change required).
		 * 
		 * @type {String}
		 */
		mode: "login",

		/**
		 * Cached auth state
		 *
		 * Used to cache the current state while handling password changes.
		 * 
		 * @type {Object|null}
		 */
		cachedAuthState: null,

		/**
		 * Security questions to answer
		 *
		 * @type {Object|null}
		 */
		securityQuestions: null

	};

	/**
	 * Password reset form ref
	 * 
	 * Used to submit password change.
	 *
	 * @type {ReactRef}
	 */
	pwcr = React.createRef();

	/**
	 * Complete authentication.
	 * 
	 * @return {void}
	 */
	completeAuth = () => {
		if (!(this.props.hopsAdminAuth && this.state.cachedAuthState.Foreign && this.state.cachedAuthState.HopsAdmin)) {
			dAuth(this.state.cachedAuthState);
		}
		if (this.props.onAuth) this.props.onAuth();
	};

	/**
	 * Complete password change.
	 * 
	 * @return {void}
	 */
	completePwcr = () => {

		this.setState({loading: true});

		AuthService.refresh(this.state.cachedAuthState?.AuthToken).then(auth => {
			return this.handleAuth(auth, true);
		}).catch(e => {
			this.setState({
				username: "",
				password: "",
				emailPwc: "",
				error: null,
				foreign: false,
				loading: false,
				mode: "login",
				cachedAuthState: null
			});
			this.props.snacke(`Error logging you in: ${e}.`);
		});

	};

	/**
	 * Authenticated.
	 *
	 * @param {Object} auth Auth object from `AuthService`
	 * @param {Boolean} disablePwcr optional Disable PWCR check (`false`)
	 * @return {Promise}
	 */
	handleAuth = (auth, disablePwcr=false) => {
		return AuthService.getAccount(auth.AuthToken).then(acc => {

			const authState = AuthService.prepareAuthStateObject(auth, acc);

			this.setState({cachedAuthState: authState}, () => {
				if (authState.Foreign && !this.props.allowForeign && !(this.props.hopsAdminAuth && authState.HopsAdmin)) {
					this.setState({foreign: true, loading: false});
				}
				else if (auth.Pwcr && !disablePwcr) {
					this.setState({mode: "pwcr", loading: false, securityQuestions: auth.SecurityQuestions});
				}
				else this.completeAuth();
			});

		});
	};

	/**
	 * Form value changed.
	 *
	 * @param {String} value
	 * @param {String} name
	 * @return {void}
	 */
	handleChange = (value, name) => this.setState({[name]: value, error: null});

	/**
	 * Foreign dialog closed.
	 * 
	 * @return {void}
	 */
	handleForeignClose = () => this.setState({foreign: false});

	/**
	 * Key press handler.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKey = e => {
		if (e.which === 13) {
			this.handleSubmit();
		}
	};

	/**
	 * Password change wanted.
	 * 
	 * @return {void}
	 */
	handlePwc = () => this.setState({mode: "pwc"});

	/**
	 * Password change cancelled.
	 * 
	 * @return {void}
	 */
	handlePwcCancel = () => this.setState({mode: "login"});

	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	handleSubmit = () => {

		if (this.state.mode === "pwcr") {
			if (this.pwcr) {
				this.pwcr.handleSubmit();
			}
			return;
		}
		else if (this.state.mode === "pwc") {
			this.handleSubmitPwc();
			return;
		}

		if (this.form?.current.reportValidity()) {

			this.setState({error: null, loading: true});

			if (this.props.onSubmitting) this.props.onSubmitting();

			/*
			 * HOPS "shortcodes" are silently ignored
			 * We want the user to get to their destination.
			 */
			AuthService.auth(this.usernameSubmission, this.state.password).then(auth => {
				return this.handleAuth(auth);
			}).catch(e => {

				const status = e?.response?.status;

				if ([401, 404].includes(status)) {
					this.setState({error: status});
				}
				else if (status === 403) {
					this.props.snacke("You can't login at the moment.");
				}
				else {
					this.setState({error: true});
					this.props.snacke("Unhandled error.");
				}

			}).finally(() => {
				this.setState({loading: false});
				if (this.props.onSubmittingDone) this.props.onSubmittingDone();
			});

		}

	};

	/**
	 * Password reset submitting.
	 * 
	 * @return {void}
	 */
	handleSubmitPwc = () => {

		if (this.form?.current.reportValidity()) {

			this.setState({error: null, loading: true});

			AuthService.pwr(this.usernameSubmission, this.state.emailPwc).then(() => {
				this.props.snacks("We'll send you an email if your details match our records.");
				this.handlePwcCancel();
				setTimeout(() => {
					if (this.usernameInput.current) {
						this.usernameInput.current.focus();
					}
				});
			}).catch(() => {
				this.setState({error: true});
				this.props.snacke("Unhandled error.");
			}).finally(() => {
				this.setState({loading: false});
			});

		}

	};

	/**
	 * Store loading state.
	 *
	 * @param {Boolean} loading
	 * @return {void}
	 */
	storeLoading = loading => this.setState({loading});

	/**
	 * Store password form ref.
	 *
	 * @param {ReactRef} ref
	 * @return {void}
	 */
	storePwcrRef = ref => (this.pwcr = ref);


	/**
	 * Component updated.
	 *
	 * Refocuses inputs when needed.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {

		if (prevState.mode !== this.state.mode) {
			if (this.usernameInput.current) {
				this.usernameInput.current.focus();
			}
		}

		if (prevState.error !== this.state.error) {
			if ((this.state.error === 401) && this.passwordInput.current) {
				setTimeout(() => this.passwordInput.current.focus());
			}
			else if (this.state.error && this.usernameInput.current) {
				setTimeout(() => this.usernameInput.current.focus());
			}
		}

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Container gap={1.5}>
					<Container
						columns={(!this.props.isMobile ? "repeat(2, max-content)" : null)}
						gap={(!this.props.isMobile ? 2 : 0.5)}
						justifyContent="space-between">
						<String bold={true} str={this.caption} />
						{((this.state.mode !== "pwcr") && this.renderLink())}
					</Container>

					{((this.state.mode !== "pwcr") && this.renderForm())}
					{((this.state.mode === "pwcr") && this.renderPwcr())}

					<PoweredByHops
						gap={(this.props.isMobile && 2)}
						label="Login is powered by HOPS."
						maxTextWidth="32rem"
						str={this.pbhString} />

					<Container
						columns={(!this.props.onCancel ? "1fr" : "repeat(2, max-content)")}
						justifyContent="space-between">
						<Button
							loading={this.state.loading}
							onClick={this.submitAction}
							label={this.submitLabel}
							type="submit" />
						{(this.props.onCancel && this.renderCancel())}
					</Container>
				</Container>

				<LoginForeignErrorDialog
					onClose={this.handleForeignClose}
					open={this.state.foreign} />
			</React.Fragment>
		);
	}


	/**
	 * Render cancel button.
	 * 
	 * @return {ReactNode}
	 */
	renderCancel() {
		return (
			<Button
				disabled={this.state.loading}
				label="Cancel"
				onClick={this.props.onCancel}
				variant="text" />
		);
	}


	/**
	 * Render the form.
	 * 
	 * @return {ReactNode}
	 */
	renderForm() {
		return (
			<form
				onKeyDown={this.handleKey}
				onSubmit={this.handleSubmit}
				ref={this.form}>
				<Container>
					{this.renderUsername()}
					{((this.state.mode === "login") && this.renderPassword())}
					{((this.state.mode === "pwc") && this.renderPwcEmail())}
				</Container>
			</form>
		);
	}


	/**
	 * Render the header link.
	 * 
	 * @return {ReactNode}
	 */
	renderLink() {
		return (
			<Link
				color="secondary"
				disabled={this.state.loading}
				endArrow={true}
				label={this.link}
				onClick={this.linkAction} />
		);
	}


	/**
	 * Render password field.
	 * 
	 * @return {ReactNode}
	 */
	renderPassword() {
		return (
			<LoginFormPasswordInput
				disabled={this.state.loading}
				loginStatus={this.state.error}
				inputRef={this.passwordInput}
				onChange={this.handleChange}
				value={this.state.password} />
		);
	}


	/**
	 * Render password change email input.
	 * 
	 * @return {ReactNode}
	 */
	renderPwcEmail() {
		return (
			<EmailAddressInput
				controlled={true}
				disabled={this.state.loading}
				name="emailPwc"
				onChange={this.handleChange}
				required={true}
				value={this.state.emailPwc} />
		);
	}


	/**
	 * Render password change required field.
	 * 
	 * @return {ReactNode}
	 */
	renderPwcr() {
		return (
			<PwcForm
				authToken={this.state.cachedAuthState?.AuthToken}
				autoFocus={true}
				disableEnableAfterDone={true}
				disableResetAfterDone={true}
				forceMobile={true}
				innerRef={this.storePwcrRef}
				noPasswordHeading={true}
				noHelp={true}
				onDone={this.completePwcr}
				onLoadingChange={this.storeLoading}
				securityQuestions={this.state.securityQuestions} />
		);
	}


	/**
	 * Render the username input.
	 * 
	 * @return {ReactNode}
	 */
	renderUsername() {
		return (
			<LoginFormUsernameInput
				disabled={this.state.loading}
				inputRef={this.usernameInput}
				loginStatus={this.state.error}
				onChange={this.handleChange}
				value={this.state.username} />
		);
	}


	/**
	 * Get the caption text.
	 * 
	 * @return {String}
	 */
	get caption() {
		switch (this.state.mode) {
			case "pwc":
				return "Password Reset";
			case "pwcr":
				return "Time to Change Your Password";
			default:
				return "Account Login";
		}
	}


	/**
	 * Get the link text.
	 * 
	 * @return {String}
	 */
	get link() {
		switch (this.state.mode) {
			case "login":
				return "Reset Password";
			default:
				return "Back to Login";
		}
	}


	/**
	 * Get the link action method.
	 *
	 * @return {Function}
	 */
	get linkAction() {
		switch (this.state.mode) {
			case "login":
				return this.handlePwc;
			case "pwc":
				return this.handlePwcCancel;
			default:
				return this.props.onCancel;
		}
	}


	/**
	 * Get our "Powered by HOPS" string.
	 * 
	 * @return {Array}
	 */
	get pbhString() {
		switch (this.state.mode) {
			case "pwc":
				return [
					"Enter your details above to reset your password. We'll send a new password to your email address if your details match our records.",
					"This will reset your password on HOPS. You'll need to use your new password everywhere you sign in with HOPS."
				];
			case "pwcr":
				return [
					"Your password must be changed before you can login.",
					"Please set a new password to continue. You'll need to use your new password everywhere you sign in with HOPS.",
					"Passwords must be 8 - 32 characters long. You cannot include your name, surname, recently used passwords or any easily guessable phrases (e.g. \"abc\", \"xyz\", \"123\", \"789\", \"password\", \"qwerty\")."
				];
			default:
				return `Please use your existing HOPS username and password if you work or volunteer with us${(this.props.allowForeign ? " or any other organisation that uses HOPS" : "")}.`;
		}
	}


	/**
	 * Get the submit action method.
	 * 
	 * @return {Function}
	 */
	get submitAction() {
		switch (this.state.mode) {
			case "pwc":
				return this.handleSubmitPwc;
			default:
				return this.handleSubmit;
		}
	}


	/**
	 * Get the submit label text.
	 * 
	 * @return {String}
	 */
	get submitLabel() {
		switch (this.state.mode) {
			case "login":
				return "Login";
			case "pwcr":
				return "Change Password";
			default:
				return "Submit";
		}
	}


	/**
	 * Get username for submission.
	 *
	 * We ignore HOPS login shortcodes.
	 * 
	 * @return {String}
	 */
	get usernameSubmission() {
		return this.state.username.split(" ")[0];
	}

}

export default withMobile(withSnack(LoginForm));
