import React from "react";

/**
 * React utility methods
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ReactHelper {

	/**
	 * Render a component instance with given props.
	 *
	 * @param {ReactComponent} Component
	 * @param {Object} Props optional
	 * @return {ReactNode}
	 */
	static render(Component, Props=null) {
		if (React.isValidElement(Component)) return Component;
		else return <Component {...Props} />;
	}

}

export default ReactHelper;
