import React from "react";
import Appv from "Helpers/Appv.js";
import Container from "Components/Container.js";
import Dialog from "Components/Dialog.js";
import String from "Components/String.js";
import F12Api from "./F12Api.js";
import F12Args from "./F12Args.js";
import F12Features from "./F12Features.js";
import F12Theme from "./F12Theme.js";
import Strings from "./F12.strings.json";
import qs from "query-string";
import withQs from "Hoc/withQs.js";

/**
 * F12
 * 
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12 extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Disabled?
			 * 
			 * @type {Boolean}
			 */
			disabled: false,

			/**
			 * Open?
			 *
			 * @type {Boolean}
			 */
			open: false

		};

		/**
		 * Method binds
		 */
		this.handleClose = this.handleClose.bind(this);
		this.handleKey = this.handleKey.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.handleSaving = this.handleSaving.bind(this);

	}


	/**
	 * Mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.updateQs();
		window.addEventListener("keydown", this.handleKey);
	}


	/**
	 * Component updated.
	 *
	 * We look for the `f12` query string key.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.location.search !== this.props.location.search) {
			this.updateQs();
		}
	}


	/**
	 * Unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		window.removeEventListener("keydown", this.handleKey);
	}


	/**
	 * Close.
	 *
	 * @return {void}
	 */
	handleClose() {
		if (this.openViaQs) {
			const p = this.props.location.pathname;
			const q = this.props.qs;
			delete q[this.constructor.qsKey];
			this.props.history.replace(`${p}${qs.stringify(q)}`);
		}
		this.setState({open: false});
	}


	/**
	 * Ctrl+F12 handler.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleKey(e) {
		if (e.ctrlKey && (e.which === 123)) {
			this.handleOpen();
		}
	}


	/**
	 * Open.
	 * 
	 * @return {void}
	 */
	handleOpen() {
		this.setState({open: true});
	}


	/**
	 * Saving data.
	 * 
	 * @return {void}
	 */
	handleSaving() {
		this.setState({disabled: true});
	}


	/**
	 * Update open state using our query string.
	 * 
	 * @return {void}
	 */
	updateQs() {
		if (this.openViaQs) this.handleOpen();
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				disabled={this.state.disabled}
				fullWidth={true}
				onClose={this.handleClose}
				open={this.state.open}
				title={Strings.title}>
				<Container>
					<F12Api
						disabled={this.state.disabled}
						onSave={this.handleSaving} />
					<F12Args />
					<F12Features />
					<F12Theme />
					<String str={`App ID: ${Appv}`} />
				</Container>
			</Dialog>
		);
	}


	/**
	 * Get whether we're open via a query string key.
	 * 
	 * @return {Boolean}
	 */
	get openViaQs() {
		return !!this.props.qs[this.constructor.qsKey];
	}


	/**
	 * Query string key
	 * 
	 * @type {String}
	 */
	static qsKey = "f12";

}

export default withQs(F12);
