import api from "api.js";
import jwt from "Helpers/Jwt.js";
import Store from "App/Store.js";

/**
 * Auth service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AuthService {

	/**
	 * Authenticate.
	 *
	 * Resolves with API authentication object.
	 *
	 * An extra property, `AuthToken` is added, as the JWT itself.
	 *
	 * Headers are overridden to ensure we ignore any existing auth.
	 * 
	 * @param {String} username
	 * @param {String} password
	 * @return {Promise} Resolves with API authentication object
	 */
	static auth(username, password) {
		return api.call({
			url: "/api/auth",
			method: "POST",
			data: {username, password},
			headers: {"Content-Type": "application/json"},
			ignoreDefaultHeaders: true
		}).then(({data}) => {
			return this.parseAuthResponse(data);
		});
	}


	/**
	 * Get the active user's account information.
	 * 
	 * This supports overriding the authentication token during e.g. login.
	 * 
	 * @param {String} auth optional Authentication token to use (`null`)
	 * @return {Promise} Resolves with user account info API object
	 */
	static getAccount(auth=null) {
		const headers = {};
		if (auth) headers["Hops-Auth"] = auth;
		return api.call({url: "/api/account/info", headers}).then(({data}) => data);
	}


	/**
	 * Refresh current authentication.
	 *
	 * @param {String} auth optional Auth token override
	 * @return {Promise}
	 */
	static refresh(auth=null) {
		const headers = (auth ? {Authorization: auth} : null);
		const request = {url: "/api/auth/ping", method: "POST"};
		if (headers) request.headers = headers;
		return api.call(request).then(({data}) => {
			return this.parseAuthResponse(data);
		});
	}


	/**
	 * Get information about a password reset token.
	 * 
	 * @param {String} token
	 * @return {Promise} Resolves with the API data
	 */
	static getPasswordResetToken(token) {
		return api.call({url: `/api/auth/pwr/${token}`}).then(({data}) => data);
	}


	/**
	 * Change the user's password.
	 *
	 * You can optionally override the authentication token to use, 
	 * e.g. when changing the password during the login flow.
	 * 
	 * @param {String|null} current Use `token` when not set
	 * @param {String} next
	 * @param {String|null} sq1a Security question 1 answer
	 * @param {String|null} sq2a Security question 2 answer
	 * @param {String|null} sq3a Security question 3 answer
	 * @param {String|null} token Password reset token (instead of `current`)
	 * @param {String} authToken optional Authentication token (`null`)
	 * @return {Promise}
	 */
	static pwc(current, next, sq1a, sq2a, sq3a, token=null, authToken=null) {

		const headers = {};
		if (authToken) headers["Hops-Auth"] = authToken;

		return api.call({
			url: `/api/account/password`,
			method: "PUT",
			data: {current: (current || null), next, sq1a: (sq1a || null), sq2a: (sq2a || null), sq3a: (sq3a || null), token: (token || null)},
			headers
		});

	}


	/**
	 * Initiate a password reset.
	 *
	 * @param {String} username
	 * @param {String} email
	 * @return {Promise}
	 */
	static pwr(username, email) {
		return api.call({
			url: `/api/account/password/reset`,
			method: "POST",
			data: {username, email}
		});
	}


	/**
	 * Get the user's assigned security questions.
	 * 
	 * @return {Promise} Resolves with API data
	 */
	static getSecurityQuestions() {
		return api.call({url: `/api/account/secqs`}).then(({data}) => data);
	}


	/**
	 * Get available security questions.
	 *
	 * @return {Promise} Resolves with API data
	 */
	static getAvailableSecurityQuestions() {
		return api.call({url: "/api/account/secqs/available"}).then(({data}) => data);
	}


	/**
	 * Set the active user's security questions.
	 *
	 * @param {Object} data API request body
	 * @return {Promise}
	 */
	static setSecurityQuestions(data) {
		return api.call({
			url: `/api/account/secqs`,
			method: "PUT",
			data
		});
	}


	/**
	 * Get auth object from an authentication response.
	 *
	 * The returned object is for our internal use.
	 * 
	 * @param {Object} res
	 * @return {Object}
	 */
	static parseAuthResponse(res) {
		return {...jwt.parse(res.auth), AuthToken: res.auth, SecurityQuestions: res.securityQuestions};
	}


	/**
	 * Prepare an authentication object to store in state as `auth`.
	 *
	 * @param {Object} auth
	 * @param {Object} account
	 * @return {Object}
	 */
	static prepareAuthStateObject(auth, account) {
		return {
			...auth,
			Foreign: !account.railways.map(o => o.Id).includes(Store.getState().org?.Id),
			HopsAdmin: account.railways.map(o => o.Id).includes(6),
			Username: account.account.Username
		};
	}

}

export default AuthService;
