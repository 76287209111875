import AuthService from "Services/AuthService.js";
import Store from "App/Store.js";
import dAuth from "Dispatchers/dAuth.js";
import dLogout from "Dispatchers/dLogout.js";

export default () => {
	return new Promise((resolve, reject) => {

		if (Store.getState().auth?.AuthToken) {

			if ((((Store.getState().auth?.Expiration || 0) * 1000) - 900) > (new Date()).getTime()) {
				AuthService.refresh().then(async auth => {

					const a = {...Store.getState().auth, ...auth};

					try {
						const acc = await AuthService.getAccount(a.AuthToken);
						const authState = AuthService.prepareAuthStateObject(a, acc);
						if (!authState.Foreign || a.Foreign) {
							dAuth(authState);
						}
						else dLogout();
						resolve();
					}
					catch (e) {
						reject(e);
					}

				}).catch(e => {

					if ([401, 403].includes(e?.response?.status)) {
						dLogout();
						resolve();
					}
					else reject(e);

				});
			}
			else {
				dLogout();
				resolve();
			}


		}
		else resolve();

	});
};
