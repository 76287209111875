import axios from "axios";
import Store from "App/Store.js";

/**
 * API class
 *
 * An abstraction around Axios to handle HOPS API interactions.
 *
 * @package HOPS
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Api {

	/**
	 * API URI
	 * 
	 * @type {String}
	 */
	static API_URI = "https://www.heritage-ops.org.uk";


	/**
	 * Create a new Axios instance using our API configuration.
	 *
	 * The base URL is determined by our `base` getter.
	 *
	 * @param {Boolean} ignoreDefaultHeaders optional (`false`)
	 * @return {Object} Axios instance
	 */
	static axios(ignoreDefaultHeaders=false) {
		return axios.create({
			baseURL: this.base,
			timeout: 60000,
			headers: (!ignoreDefaultHeaders ? this.headers : {})
		});
	}


	/**
	 * Make an API call with a given Axios request object.
	 *
	 * Uses an Axios instance obtained by calling `axios()`.
	 *
	 * @param {Object} request
	 * @return {Promise} Axios call
	 */
	static call(request) {
		return this.axios(request.ignoreDefaultHeaders).request(request);
	}


	/**
	 * Resolve a URI relative to our base.
	 * 
	 * @param {String} uri
	 * @return {String}
	 */
	static resolve(uri) {
		return `${this.base}${uri}`;
	}


	/**
	 * Get the API base URI to use.
	 *
	 * This uses the configured value in state when present, or `API_URI`.
	 * 
	 * @return {String}
	 */
	static get base() {
		return (Store.getState().api || this.API_URI);
	}


	/**
	 * Get our headers.
	 */
	static get headers() {

		const auth = Store.getState().auth;
		const org = Store.getState().org;
		const headers = {"Content-Type": "application/json"};

		if (auth?.AuthToken) {
			headers["Hops-Auth"] = auth.AuthToken;
			if (!auth.Foreign) headers["Hops-Active-Org-Id"] = org.Id;
		}

		return headers;

	}

}

export default Api;
