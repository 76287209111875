import React from "react";

/**
 * Suspense error gate
 *
 * Handle chunk load errors by refreshing.
 *
 * Addresses errors when the server build has changed.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SuspenseGate extends React.PureComponent {

	/**
	 * Component caught an error.
	 *
	 * We refresh when the error is one due to a chunk loading error.
	 *
	 * @param {Error} error
	 * @return {void}
	 */
	// eslint-disable-next-line class-methods-use-this
	componentDidCatch(error) {
		if ((["ChunkLoadError", "SyntaxError"].includes(error?.name) || (error?.message && (error?.message?.match(/Loading chunk .+? failed/) || error?.message?.match(/Loading CSS chunk .+? failed/) || error?.message?.match(/Unexpected token/)))) && navigator.onLine) {
			window.location.reload();
		}
		else throw error;
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return this.props.children;
	}

}

export default SuspenseGate;
