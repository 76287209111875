import React from "react";
import Loader from "./Loader.js";
import scss from "./ButtonBase.module.scss";
import {Button} from "@material-ui/core";

export default props => (
	<Button
		classes={props.classes}
		className={`${props.className} ${(!props.noDefaultDimensions ? scss.ButtonBase : "")}`.trim()}
		color={(props.color || "secondary")}
		disabled={(props.disabled || props.loading)}
		endIcon={props.endIcon}
		onClick={props.onClick}
		size={props.size}
		startIcon={(!(props.loading && props.showLabelWhileLoading) ? props.startIcon : (props.loading ? <Loader color="secondary" size={((props.size === "large") ? 22 : 20)} /> : undefined))}
		style={props.style}
		type={props.type}
		variant={(props.variant || "contained")}>
		{((!props.loading || props.showLabelWhileLoading) ? props.label : <Loader color="secondary" size={((props.size === "large") ? 23 : 20)} />)}
	</Button>
);
