import React from "react";
import Css from "Helpers/Css.js";
import withTheme from "Hoc/withTheme.js";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from "@material-ui/core";

/**
 * Theme Provider
 *
 * Uses the active registration's accent colour.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ThemeProvider extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Theme object
			 * 
			 * @type {Object}
			 */
			theme: createMuiTheme(this.props.resolvedTheme)

		};

	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.updateCssVariables();
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {

		const theme = (prevProps.theme !== this.props.theme);
		const thememui = (prevProps.themeMaterial !== this.props.themeMaterial);
		const mediaquery = (prevProps.mediaQuery !== this.props.mediaQuery);

		if (theme || thememui || mediaquery) {
			this.setState({theme: createMuiTheme(this.props.resolvedTheme)});
		}

		if (this.state.theme !== prevState.theme) {
			this.updateCssVariables();
		}

	}


	/**
	 * Update CSS variable values.
	 * 
	 * @return {void}
	 */
	updateCssVariables() {

		Object.keys(this.state.theme.palette.background).forEach(key => {
			const value = this.state.theme.palette.background[key];
			Css.setCustomProperty(`palette-background-${key}`, value);
		});

		Object.keys(this.state.theme.palette.text).forEach(key => {

			const value = this.state.theme.palette.text[key];
			const rgb = value.match(/\((.*)\)/g)?.[0]?.replace(/\(|\)/g, "")?.split(",")?.splice(0, 3)?.join(",");

			Css.setCustomProperty(`palette-text-${key}`, value);
			Css.setCustomProperty(`palette-text-${key}-rgb`, (rgb || value));

		});

		Css.setCustomProperty(`palette-primary`, this.state.theme.palette.primary.main);

		document.documentElement.setAttribute("palette-type", this.state.theme.palette.type);

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiThemeProvider theme={this.state.theme}>
				{this.props.children}
			</MuiThemeProvider>
		);
	}

}

export default withTheme(ThemeProvider);
