/**
 * JWT
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Jwt {

	/**
	 * Get the payload from a JWT.
	 *
	 * @param {String} token
	 * @return {mixed}
	 */
	static parse(token) {
		const pl = (token.split(".")[1] || "");
		return JSON.parse(atob(pl.replace(/-/g, "+").replace(/_/g, "/")));
	}

}

export default Jwt;
