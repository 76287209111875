import React from "react";
import dLoading from "Dispatchers/dLoading.js";

/**
 * UI suspense component
 *
 * Handles toggling loading state as the root `Suspense` retrieves chunks.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiSuspense extends React.PureComponent {

	componentDidMount() {
		dLoading(true);
	}

	componentWillUnmount() {
		dLoading(false);
	}

	render() {
		return null;
	}

}

export default UiSuspense;
