import React from "react";
import {connect} from "react-redux";

/**
 * `withAuth()` HOC wrapper
 *
 * Makes authentication details available on components.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withAuth = Component => {

	/**
	 * `ComponentWithAuth` inner wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwa = class ComponentWithAuth extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					auth={this.props.auth}
					authed={this.authed}
					authedAny={this.authedAny}
					authedForeign={this.authedForeign} />
			);
		}


		/**
		 * Get whether we're authenticated to this railway.
		 */
		get authed() {
			return (this.authedAny && !this.props.auth?.Foreign);
		}


		/**
		 * Get whether we're authenticated - to this railway or another.
		 */
		get authedAny() {
			return !!this.props.auth?.AuthToken;
		}


		/**
		 * Get whether we're authenticated to a foreign railway.
		 */
		get authedForeign() {
			return (this.authedAny && this.props.auth?.Foreign);
		}

	};

	return connect(({auth}) => ({auth}))(cwa);

};

export default withAuth;
