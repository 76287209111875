import React from "react";
import {FormControl as FormControlMui, FormGroup, FormLabel} from "@material-ui/core";

/**
 * Form control component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class FormControl extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormControlMui>
				<FormLabel>{this.props.label}</FormLabel>
				{this.renderContent()}
			</FormControlMui>
		);
	}


	/**
	 * Render the content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		if (!this.props.grouped) return this.props.children;
		else return <FormGroup row={this.props.row}>{this.props.children}</FormGroup>;
	}

}

export default FormControl;
