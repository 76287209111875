import React from "react";
import Container from "./Container.js";
import Navigator from "App/Navigator.js";
import {Card as CardMui, CardActionArea} from "@material-ui/core";
import scss from "./Card.module.scss";

/**
 * Card component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Card extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Clicked.
	 * 
	 * @return {void}
	 */
	handleClick() {
		if (this.props.uri) {
			if (this.props.uri.includes("://")) {
				window.open(this.props.uri, "_target=blank");
			}
			else Navigator.navigate(this.props.uri);
		}
		else if (this.props.onClick) {
			this.props.onClick(this.props.value);
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<CardMui
				className={this.classes}
				classes={(!(this.props.raised || this.props.outlined) ? scss : null)}
				onClick={this.handleClick}
				raised={this.props.raised}
				ref={this.props.cardRef}
				style={this.props.style}
				variant={(this.props.outlined ? "outlined" : null)}>
				{(this.hasAction ? this.renderActionArea() : this.renderContent())}
			</CardMui>
		);
	}


	/**
	 * Render the action area.
	 * 
	 * @return {ReactNode}
	 */
	renderActionArea() {
		return (
			<CardActionArea
				component={this.props.actionAreaComponent}
				style={(!this.props.unstyledActionArea ? this.constructor.stylesArea : null)}>
				{this.renderContent()}
			</CardActionArea>
		);
	}


	/**
	 * Render the content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		return (
			<Container
				alignContent="space-between"
				autoRows="max-content"
				gap={this.props.gap}
				gridTemplateRows={this.props.gridTemplateRows}
				px={(this.props.hasOwnProperty("px") ? this.props.px : this.constructor.padding)}
				py={(this.props.hasOwnProperty("py") ? this.props.py : this.constructor.padding)}
				singleColumn={true}
				style={this.constructor.stylesContent}
				width="100%"
				{...this.forwards}>
				{this.props.children}
			</Container>
		);
	}


	/**
	 * Get classes.
	 * 
	 * @return {String}
	 */
	get classes() {
		const classes = [];
		if (this.props.className) classes.push(this.props.className);
		if (this.props.hoverTransition) classes.push("hover-transition");
		return classes.join(" ");
	}


	/**
	 * Get props to forward.
	 * 
	 * @return {Object}
	 */
	get forwards() {
		const forwards = {};
		this.constructor.propForwards.forEach(forward => {
			if (this.props.hasOwnProperty(forward)) {
				forwards[forward] = this.props[forward];
			}
		});
		return forwards;
	}


	/**
	 * Get whether we've got an action.
	 * 
	 * @return {Boolean}
	 */
	get hasAction() {
		return (!!((this.props.onClick || this.props.uri)));
	}


	/**
	 * Padding multiplier
	 * 
	 * @type {Inteer}
	 */
	static padding = 1;

	/**
	 * Styles for the `CardActionArea`.
	 * 
	 * @type {Object}
	 */
	static stylesArea = {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%"
	};

	/**
	 * Styles for the content container.
	 * 
	 * @return {Object}
	 */
	static stylesContent = {
		flexGrow: 1
	};

	/**
	 * Forwardable props.
	 * 
	 * @type {Array}
	 */
	static propForwards = ["minHeight", "pl", "pr"];

}

export default Card;
