import React from "react";
import Flex from "Components/Flex.js";
import Linkify from "linkify-react";
import {Typography} from "@material-ui/core";

/**
 * String
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class String extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const strs = this.str;
		if (!strs.length) return null;

		if (!this.props.noFlex) {
			return (
				<Linkify>
					<Flex
						alignSelf={this.props.alignSelf}
						className={this.props.classNameFlex}
						component={((this.props.component !== "p") ? this.props.component : undefined)}
						gap={(this.props.gap || 1)}
						mb={this.props.mb}>
						{this.renderStrs()}
					</Flex>
				</Linkify>
			);
		}
		else return this.renderStrs();

	}


	/**
	 * Render strings.
	 * 
	 * @return {ReactNode}
	 */
	renderStrs() {
		return this.str.map((str, key) => (
			<Typography
				className={this.props.className}
				color={this.props.color}
				component={this.props.component}
				id={this.props.id}
				key={key}
				style={this.style}
				variant={this.props.variant}>
				{str}
			</Typography>
		));
	}


	/**
	 * Get strings to render.
	 * 
	 * @return {Array}
	 */
	get str() {
		const str = this.props.str;
		const strs = (Array.isArray(str) ? str : [str]);
		return strs.map(s => s?.toString().replace(/\r/g, "").split("\n")).flat().filter(s => s);
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			display: this.props.display,
			fontFamily: this.props.fontFamily,
			fontSize: this.props.fontSize,
			fontWeight: (this.props.bold ? "bold" : undefined),
			lineHeight: this.props.lineHeight,
			textAlign: (this.props.centre ? "center" : this.props.textAlign),
			opacity: this.props.opacity,
			whiteSpace: this.props.whiteSpace,
			width: this.props.width,
			...this.props.style
		};
	}

}

export default String;
