import React from "react";
import AppService from "Services/AppService.js";
import {connect} from "react-redux";

/**
 * `withOrg` HOC wrapper
 *
 * Makes the active state organisation object available on a component as `org`.
 *
 * The value of the `org` prop will be `null` when we've no registration!
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withOrg = Component => {

	/**
	 * `ComponentWithOrg` inner wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwo = class ComponentWithOrg extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					getOrgUploadPath={this.getOrgUploadPath}
					org={(this.props.org?.Id ? this.props.org : null)}
					orgLogoSrc={AppService.getRegistrationLogo()} />
			);
		}


		/**
		 * Resolve a path via the active org's uploads directory root.
		 *
		 * @param {String} path
		 * @return {String}
		 */
		getOrgUploadPath = path => {
			return AppService.getOrgUploadsPath(this.props.org?.Id, path);
		};

	};

	return connect(({org}) => ({org}))(cwo);

};

export default withOrg;
