import React from "react";
import TextField from "Components/TextField.js";

/**
 * Password input
 * 
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormPasswordInput extends React.PureComponent {

	/**
	 * Input
	 *
	 * @type {ReactRef}
	 */
	inputRef = React.createRef();


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.loginStatus !== this.props.loginStatus) {
			if (this.props.autoFocusOnError && this.props.loginStatus) {
				setTimeout(() => {
					if (this.inputRef?.current) {
						this.inputRef.current.select();
					}
				});
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				controlled={true}
				disabled={this.props.disabled}
				error={(this.props.loginStatus === 401)}
				helperText={((this.props.loginStatus === 401) && "Password incorrect")}
				inputRef={(this.props.inputRef || this.inputRef)}
				label="Password"
				name="password"
				onChange={this.props.onChange}
				required={true}
				variant="outlined"
				type="password"
				value={this.props.value} />
		);
	}

}

export default LoginFormPasswordInput;
