import React from "react";
import Container from "Components/Container.js";

/**
 * Flex component
 *
 * A `Container` using Flex layout.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Flex extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container {...this.props} style={this.styles}>
				{this.props.children}
			</Container>
		);
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			display: (this.props.hidden ? "none" : "flex"),
			flexDirection: (this.props.columnar ? "row" : "column"),
			flexGrow: this.props.flexGrow,
			flexWrap: (this.props.wrap ? "wrap" : undefined),
			...this.props.style
		};
	}

}

export default Flex;
