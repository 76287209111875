import dCheckoutBasketDiscounts from "Dispatchers/dCheckoutBasketDiscounts.js";
import dCheckoutBasketLoading from "Dispatchers/dCheckoutBasketLoading.js";
import scss from "./CheckoutDiscountsUiInput.module.scss";
import snack from "App/SnackbarHost.js";
import withCheckoutBasket from "Hoc/withCheckoutBasket.js";
import withOrg from "Hoc/withOrg.js";
import {Button, Flex, TextField} from "@heron-web/material";
import {CheckoutDiscountService} from "@hps/hops-sdk-js";
import {useCallback, useRef, useState} from "react";

export default withCheckoutBasket(withOrg(props => {

	const {
		checkoutBasketId,
		checkoutBasketDiscounts,
		checkoutBasketLoading,
		org
	} = props;

	const inputRef = useRef();
	const [inputValue, setInputValue] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	/**
	 * Handle a submission error.
	 *
	 * @param {String} msg
	 * @return {void}
	 */
	const handleError = useCallback(msg => {
		inputRef?.current?.select?.();
		snack(msg, "error");
	}, [inputRef]);


	/**
	 * Input value changed.
	 *
	 * @param {String|null} value
	 * @return {void}
	 */
	const handleInputValueChange = useCallback(value => {
		setInputValue((value?.toUpperCase?.() || null));
	}, []);


	/**
	 * Changing whether we are in the submitting state.
	 *
	 * @param {Boolean} submitting
	 * @return {void}
	 */
	const handleIsSubmittingChange = useCallback(submitting => {
		setIsSubmitting(submitting);
		dCheckoutBasketLoading(submitting);
	}, []);


	/**
	 * Submit a request to apply a discount code.
	 *
	 * @async
	 * @param {FormEvent} e
	 * @return {void}
	 */
	const handleApplySubmit = useCallback(async e => {

		e.preventDefault();

		if (checkoutBasketLoading) {
			return;
		}

		/**
		 * Discount code
		 */
		const code = inputValue;

		/**
		 * Check the user hasn't already applied this code
		 */
		if (checkoutBasketDiscounts.find(discount => (discount.Discount.Code === code))) {
			handleError("You've already applied this discount code.");
			return;
		}

		handleIsSubmittingChange(true);

		/**
		 * We are going to try to apply this discount code to the basket
		 */
		try {

			/**
			 * Make the API call to apply the discount code
			 */
			const appliedBasketDiscounts = await CheckoutDiscountService.applyDiscountCode(
				checkoutBasketId,
				code,
				org.Id
			);

			/**
			 * Update the discounts in our basket to match what the server says
			 */
			dCheckoutBasketDiscounts(appliedBasketDiscounts);

			/**
			 * Did we successfully apply the discount code?
			 */
			if (appliedBasketDiscounts.find(d => (d.Discount.Code === code))) {
				setInputValue(null);
				snack("Your discount has been applied.", "success");
			}
			else snack(`Discount code ${code} cannot be applied to any of the items in your basket.`);

		}

		/**
		 * Something went wrong
		 */
		catch (e) {
			if (e?.response?.status === 404) {
				handleError("That discount code is invalid. Please check you've typed it correctly.");
			}
			else if (e?.response?.status === 410) {
				handleError("Sorry, the discount code has expired.");
			}
			else snack(e);
		}

		handleIsSubmittingChange(false);

	}, [checkoutBasketDiscounts, checkoutBasketId, checkoutBasketLoading, inputValue, org, handleError, handleIsSubmittingChange]);


	/**
	 * Render!
	 */
	return (
		<form
			onSubmit={handleApplySubmit}>
			<Flex
				alignItems="flex-start"
				columnar={true}
				fullWidth={true}>
				<TextField
					className={scss.input}
					color="secondary"
					inputProps={{readOnly: isSubmitting}}
					inputRef={inputRef}
					onChange={handleInputValueChange}
					pattern="[A-Z0-9]{1,}"
					placeholder="Discount Code"
					required={true}
					value={inputValue} />
				<Button
					color="secondary"
					disabled={checkoutBasketLoading}
					label="Apply"
					loading={isSubmitting}
					size="large"
					type="submit" />
			</Flex>
		</form>
	);

}));
