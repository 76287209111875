import React from "react";
import TextField from "Components/TextField.js";

/**
 * Username input
 * 
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormUsernameInput extends React.PureComponent {

	/**
	 * Input
	 *
	 * @type {ReactRef}
	 */
	inputRef = React.createRef();


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.loginStatus !== this.props.loginStatus) {
			if (this.props.autoFocusOnError && this.props.loginStatus) {
				setTimeout(() => {
					if (this.inputRef?.current) {
						this.inputRef.current.select();
					}
				});
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				autoFocus
				controlled={true}
				disabled={this.props.disabled}
				error={((this.props.loginStatus === 404) || !!this.props.error)}
				helperText={(this.props.helperText || (((this.props.loginStatus === 404) && "Unknown username")))}
				inputRef={(this.props.inputRef || this.inputRef)}
				label="Username"
				name="username"
				onChange={this.props.onChange}
				required={true}
				varchar={true}
				variant="outlined"
				value={this.props.value} />
		);
	}

}

export default LoginFormUsernameInput;
