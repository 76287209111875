import React from "react";
import DateIoMoment from "@date-io/moment";
import ErrorGate from "Components/ErrorGate.js";
import F12 from "F12/F12.js";
import History from "./History.js";
import Main from "./Main.js";
import Snackbars from "Resources/Snackbars.json";
import moment from "moment";
import ThemeProvider from "./ThemeProvider.js";
import {CssBaseline} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {PersistGate} from "redux-persist/integration/react";
import {Router} from "react-router-dom";
import {SnackbarHost} from "./SnackbarHost.js";
import {SnackbarProvider} from "@heron-web/material";
import {Store, StorePersistor} from "./Store.js";
import {Provider as ReduxProvider} from "react-redux";
import * as Sentry from "@sentry/react";

/**
 * App
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class App extends React.Component {

	/**
	 * Constructor.
	 *
	 * We configure Moment so Monday is the first day of the week.
	 *
	 * Affects e.g. Material UI Pickers calendar views.
	 *
	 * @return {self}
	 */
	constructor() {
		super();
		moment.updateLocale("en", {week: {dow: 1}});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Sentry.ErrorBoundary fallback={<ErrorGate />}>
				<ReduxProvider store={Store}>
					<PersistGate loading={null} persistor={StorePersistor}>
						<MuiPickersUtilsProvider utils={DateIoMoment}>
							<ThemeProvider>
								<SnackbarProvider NotistackProps={Snackbars}>
									<CssBaseline />
									<SnackbarHost />
									<Sentry.ErrorBoundary fallback={<ErrorGate />}>
										<Router history={History}>
											<F12 />
											<Main />
										</Router>
									</Sentry.ErrorBoundary>
								</SnackbarProvider>
							</ThemeProvider>
						</MuiPickersUtilsProvider>
					</PersistGate>
				</ReduxProvider>
			</Sentry.ErrorBoundary>
		);
	}

}

export default App;
