import dSeatReservationDialog from "Dispatchers/dSeatReservationDialog.js";
import scss from "./SeatReservationDialog.module.scss";
import withCheckoutBasket from "Hoc/withCheckoutBasket.js";
import withStrings from "Hoc/withStrings.js";
import Divider from "@material-ui/core/Divider";
import SeatReservationHandler from "./SeatReservationHandler.js";
import {memo, useCallback, useMemo} from "react";
import {connect} from "react-redux";
import {Dialog, SnackbarService, String} from "@heron-web/material";
import {SeatReservationUtils} from "@hps/hops-sdk-js";

export default connect(({seatReservationDialog}) => ({seatReservationDialog}))(withCheckoutBasket(withStrings(memo(props => {

	const {
		checkoutBasket,
		checkoutBasketLoading,
		strings,
		seatReservationDialog: targetBasketItemUuid
	} = props;


	/**
	 * Basket item we're rendering for
	 */
	const targetBasketItem = useMemo(() => checkoutBasket.find(({Uuid}) => (Uuid === targetBasketItemUuid)), [checkoutBasket, targetBasketItemUuid]);


	/**
	 * Dialog closed.
	 * 
	 * @return {void}
	 */
	const handleClose = useCallback(() => {
		dSeatReservationDialog(null);
	}, []);


	/**
	 * Submit handler.
	 *
	 * @return {void}
	 */
	const handleSubmit = useCallback(() => {
		if (SeatReservationUtils.validateBasketItemSelection(targetBasketItem, checkoutBasket)) {
			handleClose();
		}
		else SnackbarService.snack("Please complete the selection.", "error");
	}, [handleClose, checkoutBasket, targetBasketItem]);


	/**
	 * Render!
	 */
	return (
		<Dialog
			classNameActions={scss.actions}
			classNamePaper={scss.root}
			disableImplicitClose={true}
			invertActionButtons={true}
			loading={checkoutBasketLoading}
			open={!!targetBasketItem}
			onClose={handleClose}
			onOk={handleSubmit}
			title="Seat Reservation"
			width="lg">
			<String
				color="textSecondary"
				gap={0.75}
				str={strings.checkoutSeatReservation} />
			<Divider />
			<SeatReservationHandler
				basket={checkoutBasket}
				basketLoading={checkoutBasketLoading}
				targetBasketId={props.checkoutBasketId}
				targetBasketItem={targetBasketItem} />
		</Dialog>
	);

}))));
