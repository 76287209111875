import CheckoutDiscountsUiInput from "./CheckoutDiscountsUiInput.js";
import CheckoutDiscountsUiList from "./CheckoutDiscountsUiList.js";
import {Flex, String} from "@heron-web/material";

export default () => {

	return (
		<Flex
			fullWidth={true}>
			<String
				bold={true}
				noFlex={true}
				str="Discount Codes" />
			<CheckoutDiscountsUiList />
			<CheckoutDiscountsUiInput />
		</Flex>
	);

};
