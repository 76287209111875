import React from "react";
import TextField from "Components/TextField.js";
import Strings from "./F12.strings.json";
import dSet from "Dispatchers/dSet.js";
import {connect} from "react-redux";

/**
 * F12 API URI input
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Api extends React.Component {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Saving?
		 * 
		 * @type {Boolean}
		 */
		saving: false,

		/**
		 * Input value
		 *
		 * @type {String}
		 */
		value: ""

	};


	/**
	 * Value changed.
	 *
	 * @param {String} value
	 * @return {void}
	 */
	handleChange = value => this.setState({value});

	/**
	 * Save a value change.
	 * 
	 * @return {void}
	 */
	handleSave = () => {
		if (this.state.value !== this.props.api) {
			this.props.onSave();
			this.setState({saving: true});
			dSet({api: this.state.value});
			setTimeout(() => window.location.reload(), 250);
		}
	};

	/**
	 * Set the current value from props.
	 * 
	 * @return {void}
	 */
	setFromProps = () => this.setState({value: this.props.api});


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.setFromProps();
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.api !== this.props.api) {
			if (this.props.api !== this.state.value) {
				this.setFromProps();
			}
		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				controlled={true}
				disabled={(this.props.disabled || this.state.saving)}
				helperText={Strings.captions.api}
				label={Strings.api}
				onBlur={this.handleSave}
				onChange={this.handleChange}
				onEnter={this.handleSave}
				placeholder={Strings.placeholders.default}
				value={this.state.value} />
		);
	}

}

export default connect(({api}) => ({api}))(F12Api);
