import Helmet from "react-helmet";
import withOrg from "Hoc/withOrg.js";

export default withOrg(props => {

	let title = (props.org?.NameTrade || "HOPS");
	if (props.title) title += ` - ${props.title}`;

	return (
		<Helmet>
			<title>{title}</title>
			{(props.description && <meta name="description" content={props.description} />)}
		</Helmet>
	);

});
