import React from "react";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import ReactHelper from "Helpers/React.js";

/**
 * A header component intended for use with `Cardx`
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CardxHeader extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const color = (this.props.color || "secondary");

		return (
			<Flex
				alignItems="center"
				columnar={true}
				justifyContent="space-between">
				<Flex
					alignItems="center"
					columnar={true}>
					{(this.props.icon && ReactHelper.render(this.props.icon, {color}))}
					<String color={color} str={this.props.label} variant="h6" />
				</Flex>
				{this.props.action}
			</Flex>
		);

	}

}

export default CardxHeader;
