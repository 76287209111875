import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioMui from "@material-ui/core/Radio";
import scss from "./Radio.module.scss";

/**
 * Radio component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Radio extends React.PureComponent {

	/**
	 * A radio was clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick = e => {
		if (this.props.onClick) {
			this.props.onClick(e.target.value, e.target.name);
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (!this.props.label ? this.renderControl() : this.renderLabelled());
	}


	/**
	 * Render the actual control.
	 * 
	 * @return {ReactNode}
	 */
	renderControl() {
		return (
			<RadioMui
				checked={this.props.checked}
				className={`${this.props.className} ${(this.props.reducedVerticalPadding ? scss.reducedVerticalPadding : "").trim()}`}
				color="secondary"
				disabled={this.props.disabled}
				name={this.props.name}
				onClick={(this.props.onClick ? this.handleClick : undefined)}
				value={this.props.value} />
		);
	}


	/**
	 * Render with a label.
	 * 
	 * @return {ReactNode}
	 */
	renderLabelled() {
		return (
			<FormControlLabel
				control={this.renderControl()}
				disabled={this.props.disabled}
				label={this.props.label}
				value={this.props.value} />
		);
	}

}

export default Radio;
