import React from "react";
import Dialog from "Components/Dialog.js";
import String from "Components/String.js";

/**
 * Foreign login prohibited error dialog
 *
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginForeignErrorDialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				fullWidth={true}
				open={this.props.open}
				onClose={this.props.onClose}
				title="Invalid HOPS Account">
				<String color="textSecondary" str={this.constructor.strings} />
			</Dialog>
		);
	}


	/**
	 * Strings.
	 * 
	 * @type {Array}
	 */
	static strings = [
		"You can't login using these credentials.",
		"Your HOPS account isn't registered with this organisation."
	];

}

export default LoginForeignErrorDialog;
