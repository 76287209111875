import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import BasketExpiryTimer from "./BasketExpiryTimer.js";
import Button from "Components/Button.js";
import CheckoutService from "Services/CheckoutService.js";
import DialogInterstitial from "Components/DialogInterstitial.js";
import Flex from "Components/Flex.js";
import Icon from "@material-ui/icons/Timer";
import String from "Components/String.js";
import Strings from "./BasketExpirationInterstitial.strings.json";
import dCheckoutBasketReset from "Dispatchers/dCheckoutBasketReset.js";
import scss from "./BasketExpirationInterstitial.module.scss";
import withCheckoutBasket from "Hoc/withCheckoutBasket.js";
import withSnack from "Hoc/withSnack.js";

/**
 * HOPS
 * 
 * @package HOPS
 * @subpackage Checkout
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class BasketExpirationInterstitial extends AsyncStatefulComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Remaining basket lifetime (s)
		 * 
		 * @type {Integer|null}
		 */
		basketLifetime: null,

		/**
		 * Extending the lifetime?
		 *
		 * @type {Boolean}
		 */
		extending: false

	};

	/**
	 * ID of interval calling `checkIsBasketExpiring()`
	 *
	 * @type {Integer|null}
	 */
	basketExpiryInterval = null;


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();

		if (this.props.checkoutBasketExpiry && (this.props.checkoutBasketExpiry * 1000) <= Date.now()) {
			dCheckoutBasketReset();
			this.props.snack(Strings.expired, "error");
		}
		else this.checkIsBasketExpiring();

		this.basketExpiryInterval = setInterval(this.checkIsBasketExpiring, 1000);

	}


	/**
	 * Component updated.
	 *
	 * When the expiry time has changed, we want to immediately 
	 * update the state controlling whether we're visible.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.checkoutBasketExpiry !== this.props.checkoutBasketExpiry) {
			this.checkIsBasketExpiring();
		}
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		if (this.basketExpiryInterval) {
			clearInterval(this.basketExpiryInterval);
		}
	}


	/**
	 * Check whether the basket is currently expiring.
	 * 
	 * @return {void}
	 */
	checkIsBasketExpiring = () => {
		this.setState({basketLifetime: (this.props.checkoutBasketExpiry ? ((this.props.checkoutBasketExpiry - (Date.now() / 1000))) : null)});
	};


	/**
	 * Extend the basket's lifetime.
	 * 
	 * @return {void}
	 */
	handleExtend = () => {

		this.setState({extending: true});

		CheckoutService.extendBasketLifetime().catch(() => {
			this.props.snack(Strings.extension.error, "error");
		}).finally(() => {
			this.setState({extending: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DialogInterstitial
				hideCloseButton={true}
				open={((this.state.basketLifetime !== null) && (this.state.basketLifetime <= 30))}>
				<Flex gap={2} justifyContent="space-between">
					<Flex gap={2}>
						<Flex className={scss.header} gap={2}>
							<Icon
								className={scss.icon}
								color="primary"
								fontSize="large" />
							<String
								centre={true}
								str={Strings.title}
								variant="h5" />
							<BasketExpiryTimer expiry={this.props.checkoutBasketExpiry} />
						</Flex>
						<String
							color="textSecondary"
							str={Strings.caption} />
					</Flex>
					<Flex className={scss.buttons}>
						<Button
							label={Strings.buttons.surrender}
							onClick={dCheckoutBasketReset}
							variant="outlined" />
						<Button
							disabled={(this.state.basketLifetime <= 5)}
							label={Strings.buttons.extend}
							loading={this.state.extending}
							onClick={this.handleExtend}
							showLabelWhileLoading={true}
							size="large" />
					</Flex>
				</Flex>
			</DialogInterstitial>
		);
	}

}

export default withCheckoutBasket(withSnack(BasketExpirationInterstitial));
