import React from "react";
import {Checkbox as MuiCheckbox, FormControlLabel} from "@material-ui/core";

/**
 * Checkbox component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Checkbox extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Changed.
	 * 
	 * @return {void}
	 */
	handleChange() {
		this.props.onChange(this.props.value, !this.props.checked);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormControlLabel
				control={this.renderCheckbox()}
				label={this.props.label} />
		);
	}


	/**
	 * Render the checkbox.
	 * 
	 * @return {ReactNode}
	 */
	renderCheckbox() {
		return (
			<MuiCheckbox
				checked={this.props.checked}
				color="secondary"
				disabled={this.props.disabled}
				onChange={this.handleChange}
				value={this.props.value} />
		);
	}

}

export default Checkbox;
