import React from "react";
import Button from "./Button.js";
import Flex from "./Flex.js";
import Loader from "./Loader.js";
import PropTypes from "prop-types";
import String from "./String.js";
import {Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

/**
 * HOPS re-implementation of Heron Web Dialog (Abstraction of Material UI's `Card`)
 * @heron-web/material is no longer maintained by James Walker.
 * 
 */
class Dialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiDialog
				className={this.props.className}
				disableBackdropClick={(this.disabled || this.props.disableImplicitClose)}
				disableEscapeKeyDown={(this.disabled || this.props.disableImplicitClose)}
				disableScrollLock={!this.props.useScrollLock}
				fullScreen={this.props.fullScreen}
				fullWidth={this.props.fullWidth}
				maxWidth={this.props.width}
				PaperProps={this.paperProps}
				onClose={(!this.disabled ? this.props.onClose : undefined)}
				open={this.props.open}
				TransitionComponent={this.props.transition}
				TransitionProps={this.props.transitionProps}
				transitionDuration={(this.props.noTransition ? 0 : this.props.transitionDuration)}>
				{((this.props.title || this.props.loading) && this.renderTitle())}
				{this.renderContent()}
				{(this.hasActions && this.renderActions())}
			</MuiDialog>
		);
	}


	/**
	 * Render our actions.
	 * 
	 * @return {ReactNode}
	 */
	renderActions() {
		return (
			<DialogActions className={this.props.classNameActions}>
				{this.props.actions}
				{this.renderDefaultActions()}
			</DialogActions>
		);
	}


	/**
	 * Render the content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {

		const children = (this.props.children ? this.renderChildren() : this.renderText());

		if (!this.props.noContent) {
			return (
				<DialogContent className={this.props.classNameContent} dividers={this.props.contentDividers}>
					{children}
				</DialogContent>
			);
		}
		else return children;

	}


	/**
	 * Render our children.
	 *
	 * @return {ReactNode}
	 */
	renderChildren() {
		if (!this.props.noFlex) {
			return (
				<Flex
					gap={this.props.gap}
					mb={this.effectiveContentPaddingBottom}
					{...this.props.FlexProps}>
					{this.props.children}
				</Flex>
			);
		}
		else return this.props.children;
	}


	/**
	 * Render default actions.
	 * 
	 * @return {ReactNode}
	 */
	renderDefaultActions() {

		const onOk = this.props.onOk;
		const hasOk = (onOk && !this.props.noOkButton);
		const hasClose = (!this.props.noCloseButton);
		const disableClose = this.props.allowCloseWhenDisabled ? false : this.disabled;

		const closeBtn = (hasClose && <Button disabled={disableClose} label={(this.props.closeLabel || (!hasOk ? "OK" : "Cancel"))} onClick={this.props.onClose} variant="text" />);

		return (
			<Flex alignItems="center" columnar={true} hidden={(!hasClose && !hasOk)}>
				{(!this.props.invertActionButtons && closeBtn)}
				{(hasOk && <Button disabled={this.disabled} label={this.props.okLabel} onClick={onOk} variant="contained" />)}
				{(this.props.invertActionButtons && closeBtn)}
			</Flex>
		);

	}


	/**
	 * Render our text.
	 * 
	 * @return {ReactNode}
	 */
	renderText() {
		return (
			<String
				color="textSecondary"
				str={this.props.str}
				{...this.stringProps} />
		);
	}


	/**
	 * Render the title.
	 *
	 * @return {ReactNode}
	 */
	renderTitle() {
		return (
			<Flex
				alignItems="center"
				columnar={true}
				justifyContent="space-between"
				pr={1}>
				<DialogTitle className={this.props.classNameTitle}>{this.props.title}</DialogTitle>
				{(this.props.loading && <Loader size={25} />)}
			</Flex>
		);
	}


	/**
	 * Get whether the controls should be disabled.
	 * 
	 * @return {Boolean}
	 */
	get disabled() {
		return (this.props.disabled || this.props.loading);
	}


	/**
	 * Get the effective padding bottom multiplier to apply to 
	 * our content container as the appropriate amount varies 
	 * depending on the components we're using.
	 * 
	 * @return {Number}
	 */
	get effectiveContentPaddingBottom() {
		return ((!this.props.contentDividers && (!this.props.actions && (this.props.noCloseButton && (!this.props.onOk || this.props.noOkButton))) ? (this.props.contentPaddingBottom * (!this.props.title ? 0.75 : 1)) : 0));
	}


	/**
	 * Get whether we have actions to render.
	 *
	 * @return {ReactNode}
	 */
	get hasActions() {
		return (!!(this.props.actions || !this.props.noCloseButton || (this.props.onOk && !this.props.noOkButton)) && !this.props.noActions);
	}


	/**
	 * `PaperProps`
	 *
	 * @return {Object}
	 */
	get paperProps() {
		return {
			className: this.props.classNamePaper,
			elevation: this.props.elevation,
			square: this.props.square,
			variant: this.props.variant,
			...this.props.PaperProps
		};
	}


	/**
	 * Props to apply to our `String`
	 * 
	 * @return {Object}
	 */
	get stringProps() {
		return {
			FlexProps: {
				mb: this.effectiveContentPaddingBottom,
				...this.props.StringProps?.FlexProps
			},
			...this.props.StringProps
		};
	}


	static propTypes = {
		FlexProps: PropTypes.object,
		PaperProps: PropTypes.object,
		StringProps: PropTypes.object,
		actions: PropTypes.node,
		allowCloseWhenDisabled: PropTypes.bool,
		children: PropTypes.node,
		className: PropTypes.string,
		classNameActions: PropTypes.string,
		classNameContent: PropTypes.string,
		classNamePaper: PropTypes.string,
		classNameTitle: PropTypes.string,
		closeLabel: PropTypes.string,
		contentDividers: PropTypes.bool,
		contentPaddingBottom: PropTypes.number,
		disabled: PropTypes.bool,
		disableImplicitClose: PropTypes.bool,
		elevation: PropTypes.number,
		fullScreen: PropTypes.bool,
		fullWidth: PropTypes.bool,
		gap: PropTypes.number,
		invertActionButtons: PropTypes.bool,
		loading: PropTypes.bool,
		noContent: PropTypes.bool,
		noFlex: PropTypes.bool,
		noCloseButton: PropTypes.bool,
		noOkButton: PropTypes.bool,
		noTransition: PropTypes.bool,
		okLabel: PropTypes.string,
		onClose: PropTypes.func,
		onOk: PropTypes.func,
		open: PropTypes.bool,
		square: PropTypes.bool,
		title: PropTypes.string,
		transition: PropTypes.element,
		transitionDuration: PropTypes.number,
		transitionProps: PropTypes.object,
		useScrollLock: PropTypes.bool,
		variant: PropTypes.oneOf(["elevation", "outlined"]),
		width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false])
	};

	static defaultProps = {
		allowCloseWhenDisabled: false,
		contentDividers: false,
		contentPaddingBottom: 0.75,
		disabled: false,
		disableImplicitClose: false,
		elevation: 0,
		fullScreen: false,
		fullWidth: true,
		gap: 1,
		invertActionButtons: false,
		loading: false,
		noContent: false,
		noCloseButton: false,
		noOkButton: false,
		noFlex: false,
		noTransition: false,
		okLabel: "OK",
		open: false,
		square: false,
		useScrollLock: false,
		variant: "elevation",
		width: "sm"
	};

}

export default Dialog;
