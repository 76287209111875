import React from "react";
import Checkbox from "Components/Checkbox.js";
import FormControl from "Components/FormControl.js";
import Features from "Resources/Features.js";
import Strings from "./F12.strings.json";
import dFeaturesForced from "Dispatchers/dFeaturesForced.js";
import withFeatures from "Hoc/withFeatures.js";

/**
 * F12 feature overrides UI
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Features extends React.Component {

	/**
	 * Feature toggled.
	 * 
	 * @param {String} feature
	 * @return {void}
	 */
	handleChange = feature => {
		let features;
		if (this.props.isFeatureEnabled(feature)) {
			features = this.props.featuresForced.filter(f => (f !== feature));
		}
		else features = [...this.props.featuresForced, feature];
		dFeaturesForced(features);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormControl
				grouped={true}
				label={(!this.props.noLabel ? Strings.features : null)}
				row={!this.props.vertical}>
				{this.renderFeatures()}
			</FormControl>
		);
	}


	/**
	 * Render features.
	 * 
	 * @return {ReactNode}
	 */
	renderFeatures() {
		return (this.props.featureOptions || Features).map((feature, key) => (
			<Checkbox
				checked={this.props.isFeatureEnabled(feature.name)}
				disabled={this.props.isLiveFeature(feature.name)}
				key={key}
				label={feature.label}
				onChange={this.handleChange}
				value={feature.name} />
		));
	}

}

export default withFeatures(F12Features);
