import React from "react";
import ReactHelper from "Helpers/React.js";
import {IconButton, Tooltip} from "@material-ui/core";
import scss from "./IconButton.module.scss";

export default props => (
	<Tooltip arrow={true} title={(props.label || "")}>
		<div className={props.className}>
			<IconButton
				className={(!props.defaultStyling ? scss.IconButton : undefined)}
				classes={props.classes}
				color={props.color}
				disabled={props.disabled}
				onClick={props.onClick}
				size={props.size}
				style={props.style}>
				{ReactHelper.render(props.icon)}
			</IconButton>
		</div>
	</Tooltip>
);
