import React from "react";
import ActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "./CardxHeader.js";
import CardMui from "@material-ui/core/Card";
import Flex from "Components/Flex.js";
import Navigator from "App/Navigator.js";
import scss from "./Cardx.module.scss";
import scssCard from "./Card.module.scss";

/**
 * Card component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Cardx extends React.PureComponent {

	/**
	 * Clicked.
	 *
	 * @return {void}
	 */
	handleClick = () => {
		if (this.props.uri) {
			Navigator.navigate(this.props.uri);
		}
		else if (this.props.onClick) {
			this.props.onClick(this.props.value, this.props.name);
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<CardMui
				classes={this.props.classes}
				className={`${(this.props.className || "")} ${(!(this.props.raised || this.props.outlined || (this.props.elevation === 0)) ? scssCard.root : "")}`.trim()}
				elevation={this.props.elevation}
				onClick={(!this.props.disabled ? this.handleClick : undefined)}
				raised={this.props.raised}
				ref={this.props.rootRef}
				square={this.props.square}
				style={this.props.style}
				variant={(this.props.outlined ? "outlined" : null)}>
				{(this.hasAction ? this.renderActionArea() : this.renderContent())}
			</CardMui>
		);
	}


	/**
	 * Render the action area.
	 * 
	 * @return {ReactNode}
	 */
	renderActionArea() {
		return (
			<ActionArea
				className={`${scss.actionArea} ${(this.props.aaClassName || "").trim()}`}
				disabled={this.props.disabled}>
				{this.renderContent()}
			</ActionArea>
		);
	}


	/**
	 * Render the content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		return (
			<Flex
				className={`${scss.container} ${(this.props.containerClassName || "").trim()}`}
				columnar={this.props.columnar}
				gap={((this.props.gap !== undefined) ? this.props.gap : 1)}
				px={((this.props.px !== undefined) ? this.props.px : 1)}
				py={((this.props.py !== undefined) ? this.props.py : 1)}
				style={this.props.containerStyle}>
				{(this.props.headerLabel && this.renderHeader())}
				{this.props.children}
			</Flex>
		);
	}


	/**
	 * Render the header.
	 * 
	 * @return {ReactNode}
	 */
	renderHeader() {
		return (
			<CardHeader
				action={this.props.headerAction}
				color={this.props.headerColor}
				icon={this.props.headerIcon}
				label={this.props.headerLabel} />
		);
	}


	/**
	 * Get whether we've got an on-click action.
	 *
	 * @return {Boolean}
	 */
	get hasAction() {
		return (!!(this.props.onClick || this.props.uri));
	}

}

export default Cardx;
