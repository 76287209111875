import React from "react";
import Container from "./Container.js";
import Loader from "./Loader.js";
import ReactHelper from "Helpers/React.js";
import {Train as ErrorImage} from "@material-ui/icons";

/**
 * Image component
 *
 * An image with loading and error states.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Image extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Error?
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading?
			 *
			 * @type {Boolean}
			 */
			loading: true

		};

		/**
		 * Method binds
		 */
		this.handleError = this.handleError.bind(this);
		this.handleLoad = this.handleLoad.bind(this);

	}


	/**
	 * Errored.
	 *
	 * @return {void}
	 */
	handleError() {
		this.setState({error: true, loading: false});
	}


	/**
	 * Loaded.
	 *
	 * @return {void}
	 */
	handleLoad() {
		this.setState({loading: false});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (!this.state.error ? this.renderMain() : (!this.props.noError ? this.renderError() : null));
	}


	/**
	 * Render error state.
	 * 
	 * @return {ReactNode}
	 */
	renderError() {
		return (
			<Container
				alignSelf={(this.props.errorAlign || "center")}
				justifySelf={(this.props.errorJustify || "center")}>
				{ReactHelper.render((this.props.errorIcon || ErrorImage), {color: "primary"})}
			</Container>
		);
	}


	/**
	 * Render the main content.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Container
				alignItems={this.props.alignItems}
				display="flex"
				justifyContent={this.props.justifyContent}
				overflow="hidden"
				style={{position: "relative", ...this.props.style}}>
				<img
					alt={this.props.alt}
					className={this.props.imgClassName}
					onError={this.handleError}
					onLoad={this.handleLoad}
					src={this.props.src}
					style={this.imgStyles} />
				{((this.state.loading && !this.props.noLoader) ? <Loader absolute={true} {...this.props.loaderProps} /> : null)}
			</Container>
		);
	}


	/**
	 * Get our opacity.
	 *
	 * This is to prevent a flash of the browser error image placeholder 
	 * when we are using the `noError` option.
	 *
	 * @return {Float}
	 */
	get opacity() {
		if (!this.state.loading && !this.state.error) return 1;
		else if (this.state.loading && !this.props.noLoader) return 1;
		else if (this.state.error && !this.props.noError) return 1;
		else return 0;
	}


	/**
	 * Image styles.
	 *
	 * @type {Object}
	 */
	get imgStyles() {
		return {
			objectFit: (this.props.objectFit || "cover"),
			opacity: this.opacity,
			...this.props.imgStyles
		};
	}

}

export default Image;
