import React from "react";
import snack from "App/SnackbarHost.js";
import {withSnackbar} from "notistack";

/**
 * `withSnack()` HOC wrapper
 *
 * Wraps components with notistack convenience methods.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withSnack = Component => {

	/**
	 * `ComponentWithSnack` inner wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cws = class ComponentWithSnack extends React.PureComponent {

		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					snack={snack}
					snacke={this.snacke}
					snacks={this.snacks} />
			);
		}

		/**
		 * Show an error snackbar.
		 * 
		 * @param {String} msg
		 * @return {void}
		 */
		snacke = msg => snack(msg, "error");

		/**
		 * Show a success snackbar.
		 * 
		 * @param {String} msg
		 * @return {void}
		 */
		snacks = msg => snack(msg, "success");

	};

	return withSnackbar(cws);

};

export default withSnack;
