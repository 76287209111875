import React from "react";
import FormControl from "./FormControl.js";
import RadioGroupMui from "@material-ui/core/RadioGroup";
import scssRadios from "./Radio.module.scss";

/**
 * Radio group component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class RadioGroup extends React.PureComponent {

	/**
	 * Change handler.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange = e => {

		let value = e.target.value;

		if (!isNaN(value)) {
			value = parseInt(value);
		}

		if (this.props.matchValue) {
			value = this.props.matchValue(value);
		}

		this.props.onChange(value, this.props.name);

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (this.props.label ? this.renderLabelled() : this.renderMain());
	}


	/**
	 * Render with a label.
	 * 
	 * @return {ReactNode}
	 */
	renderLabelled() {
		return (
			<FormControl label={this.props.label}>
				{this.renderMain()}
			</FormControl>
		);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<RadioGroupMui
				className={`${this.props.className} ${(this.props.reducedVerticalPadding ? scssRadios.reducedVerticalPadding : "").trim()}`}
				disabled={this.props.disabled}
				onChange={this.handleChange}
				row={this.props.row}
				value={this.props.value}>
				{this.props.children}
			</RadioGroupMui>
		);
	}

}

export default RadioGroup;
