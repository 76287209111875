import React from "react";
import dArgs from "Dispatchers/dArgs.js";
import Strings from "./F12.strings.json";
import TextField from "Components/TextField.js";
import {connect} from "react-redux";

/**
 * F12 arguments input
 *
 * A component for inputting an arguments string (form of feature flags).
 * 
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Args extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				label={Strings.args.label}
				helperText={Strings.args.helperText}
				multiline={true}
				onChange={args => dArgs((args?.replaceAll("\n", ";") || null))}
				placeholder={Strings.args.placeholder}
				shrink={true}
				value={this.props.args} />
		);
	}

}

export default connect(({args}) => ({args}))(F12Args);
