import React from "react";
import UiRoutes from "./UiRoutes.js";
import withAuth from "Hoc/withAuth.js";
import withFeatures from "Hoc/withFeatures.js";
import wrapRoute from "Wrappers/RouteWrapper.js";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";

/**
 * UI router
 *
 * The main application router.
 * 
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiRouter extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch>
				{UiRoutes.map((route, key) => this.renderRoute(route, key))}
			</Switch>
		);
	}


	/**
	 * Render a route.
	 *
	 * @param {Object} route
	 * @return {ReactNode|null}
	 */
	renderRoute(route) {

		if (route.feature && !this.props.isFeatureEnabled(route.feature)) {
			return null;
		}
		else if (route.publicOnly && this.props.authedAny) {
			return null;
		}

		return (
			<Route
				component={this.constructor.getRouteWrapper(route)}
				exact={!route.inexact}
				key={(!route.preserveRkey ? `${route.uri}-${this.props.rkey}` : route.uri)}
				path={route.uri} />
		);

	}


	/**
	 * Get a wrapped route.
	 *
	 * Wraps the route's component using `RouteWrapper`.
	 *
	 * @param {Object} route
	 * @return {ReactComponent}
	 */
	static getRouteWrapper(route) {
		if (!route.wrapped) {
			route.wrapped = wrapRoute(route);
		}
		return route.wrapped;
	}

}

export default connect(({rkey}) => ({rkey}))(withAuth(withFeatures(UiRouter)));
