import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Flex from "Components/Flex.js";
import String from "Components/String.js";

/**
 * Basket reservation countdown timer component
 * 
 * @package HOPS
 * @subpackage Checkout
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class BasketExpiryTimer extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Currently displayed time remaining string
		 *
		 * @type {String|null}
		 */
		timeString: null

	};

	/**
	 * Interval ID for the time string updater
	 *
	 * @type {Integer|null}
	 */
	interval = null;


	/**
	 * Component mounted.
	 *
	 * Update the time string immediately and initialise interval updates.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();
		this.updateTimeString();
		this.interval = setInterval(this.updateTimeString, 1000);
	}


	/**
	 * Component unmounting.
	 *
	 * We clear the time string updater interval.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		clearInterval(this.interval);
	}


	/**
	 * Component updated.
	 *
	 * Update the time string immediately when the expiry has changed.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.expiry !== this.props.expiry) {
			this.updateTimeString();
		}
	}


	/**
	 * Update the time remaining string.
	 *
	 * @return {void}
	 */
	updateTimeString = () => {

		const seconds = (this.props.expiry - Math.floor((Date.now() / 1000)));
		const displayAsMinutes = (seconds > 60);

		const displayed = (displayAsMinutes ? Math.ceil((seconds / 60)) : seconds);
		const displayedUnit = (displayAsMinutes ? "minutes" : "seconds");

		this.setState({timeString: `${displayed} ${displayedUnit}`});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex>
				<String
					centre={true}
					color={(!this.isExpired ? "primary" : "error")}
					str={this.timeString} />
			</Flex>
		);
	}


	/**
	 * Get whether we've expired.
	 * 
	 * @return {Boolean}
	 */
	get isExpired() {
		return (Math.floor((Date.now() / 1000)) >= this.props.expiry);
	}


	/**
	 * Get the time string to display.
	 * 
	 * @return {String}
	 */
	get timeString() {
		if (this.isExpired) return `Your basket reservation has expired.`;
		else return `Your items are reserved for the next ${this.state.timeString}.`;
	}

}

export default BasketExpiryTimer;
