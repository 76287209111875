import React from "react";
import Radio from "Components/Radio.js";
import RadioGroup from "Components/RadioGroup.js";
import dThemeMaterial from "Dispatchers/dThemeMaterial.js";
import withTheme from "Hoc/withTheme.js";

/**
 * App theme selector
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Theme extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<RadioGroup
				label="Theme"
				onChange={dThemeMaterial}
				row={true}
				value={this.props.themeMaterial}>
				<Radio label="Dark" value="dark" />
				<Radio label="Light" value="auto" />
			</RadioGroup>
		);
	}

}

export default withTheme(F12Theme);
