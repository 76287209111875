import CheckoutService from "Services/CheckoutService.js";
import {memo, useCallback} from "react";
import {SnackbarService} from "@heron-web/material";
import {SeatReservationCheckoutUi} from "@hps/hops-react";
import {CheckoutBasketItem, ItemClaimErrorCodes, OrderableTypes} from "@hps/hops-sdk-js";

export default memo(props => {

	const {basket, basketLoading, targetBasketItem, targetBasketId} = props;


	/**
	 * Get the `BasketItem` in the local checkout basket corresponding 
	 * to a given seat reservation by its train and space IDs.
	 *
	 * @param {Integer} trainAssetId
	 * @param {?Integer} trainScheduleIdDeparture
	 * @param {?Integer} trainScheduleIdArrival
	 * @param {Integer} spaceId
	 * @return {Object|undefined} `CheckoutBasketItem`-like object
	 */
	const getSeatBasketItem = useCallback((trainAssetId, trainScheduleIdDeparture, trainScheduleIdArrival, spaceId) => {
		return basket.find(({Item, OrderableType}) => {
			return (
				(OrderableType === OrderableTypes.SeatReservation) &&
				(Item?.TrainAsset?.Id === trainAssetId) &&
				(Item?.Space?.Id === spaceId) &&
				(Item?.DepartureTrainSchedule === trainScheduleIdDeparture) &&
				(Item?.ArrivalTrainSchedule === trainScheduleIdArrival)
			);
		});
	}, [basket]);


	/**
	 * Adding a seat to the basket.
	 *
	 * @param {Object} train
	 * @param {Object} trainAsset
	 * @param {?Integer} trainScheduleIdDeparture
	 * @param {?Integer} trainScheduleIdArrival
	 * @param {Object} space
	 * @param {Object} relatedBasketItem
	 * @return {Boolean|ItemClaimErrorCode}
	 */
	const handleAddSeat = useCallback(async (train, trainAsset, trainScheduleIdDeparture, trainScheduleIdArrival, space, relatedBasketItem) => {

		let error = false;

		if (basketLoading) {
			return error;
		}

		/**
		 * Reserve the seat
		 */
		try {

			/**
			 * Basket items to add
			 */
			const items = [
				CheckoutBasketItem.construct({
					RelatedItemUuid: relatedBasketItem.Uuid,
					OrderableType: OrderableTypes.SeatReservation,
					Item: {
						Train: train,
						TrainAsset: trainAsset,
						Space: space,
						DepartureTrainSchedule: trainScheduleIdDeparture,
						ArrivalTrainSchedule: trainScheduleIdArrival
					},
					Price: 0,
					Quantity: 1,
					VatProportion: 0,
					VatRate: 0
				})
			];

			/**
			 * Add to the basket
			 */
			const result = await CheckoutService.addBasketItems(
				items,
				false
			);

			/**
			 * There was an error claiming the item
			 */
			if (result?.Errors?.length) {

				error = true;
				const errorCode = result?.Errors[0]?.Code;
				const unfulfilableIcEc = ItemClaimErrorCodes.default.ItemUnfulfilable;

				/**
				 * The item's already been claimed
				 */
				if (errorCode === unfulfilableIcEc) {
					SnackbarService.snack("The space has now been booked by another customer.", "error");
					return new ItemClaimErrorCodes.ItemClaimErrorCode(unfulfilableIcEc);
				}
				else SnackbarService.snack(`We're sorry - there was an error reserving the space. Please try again. (Error code ${errorCode})`, "error");

				return false;

			}

			return !!result;

		}

		/**
		 * Reservation error
		 */
		catch (e) {
			error = true;
			SnackbarService.snack(e);
		}

		/**
		 * Throw now (this is caught by `SeatReservationCheckoutUi` 
		 * and will result in the seat being removed from the local 
		 * (visual) selection so it no longer looks selected).
		 */
		if (error) {
			throw new Error();
		}

		return true;

	}, [basketLoading]);


	/**
	 * Removing a seat from the basket.
	 *
	 * @param {Integer} trainAssetId
	 * @param {?Integer} trainScheduleIdDeparture
	 * @param {?Integer} trainScheduleIdArrival
	 * @param {Integer} spaceId
	 * @return {Boolean}
	 */
	const handleRemoveSeat = useCallback(async (trainAssetId, trainScheduleIdDeparture, trainScheduleIdArrival, spaceId) => {

		if (basketLoading) {
			return false;
		}

		try {

			const basketItem = getSeatBasketItem(trainAssetId, trainScheduleIdDeparture, trainScheduleIdArrival, spaceId);

			if (basketItem) {
				const result = await CheckoutService.removeBasketItems([basketItem]);
				return !!result;
			}

			else throw new Error("Couldn't match the seat reservation in the basket.");

		}

		catch (e) {

			SnackbarService.snack(e);

			/**
			 * Rethrow the error - it'll be caught by
			 * `SeatReservationCheckoutUi` to add the 
			 * seat back to the visual selection.
			 */
			throw e;

		}

	}, [basketLoading, getSeatBasketItem]);


	/**
	 * Render!
	 */
	if (targetBasketItem) {
		return (
			<SeatReservationCheckoutUi.Component
				initialSelection={SeatReservationCheckoutUi.mapBasketItemsToSelectionState(basket.filter(i => (i.RelatedItemUuid === targetBasketItem.Uuid)))}
				onAddSelection={handleAddSeat}
				onRemoveSelection={handleRemoveSeat}
				targetBasketItem={targetBasketItem}
				targetBasketId={targetBasketId}
				showHeadcode={false} />
		);
	}
	else return null;

});
