import dCheckoutBasketClear from "./dCheckoutBasketClear.js";
import dCheckoutBasketDiscounts from "./dCheckoutBasketDiscounts.js";
import dCheckoutBasketIdentity from "./dCheckoutBasketIdentity.js";
import dCheckoutOrderVouchers from "./dCheckoutOrderVouchers.js";

/**
 * Completely reset the checkout basket
 *
 * Clears all items and the basket identity (ID/expiry) when set.
 *
 * This should only generally be used once we're sure the basket identity 
 * is no longer necessary - e.g. after successful checkout.
 * 
 * @return {void}
 */
export default () => {

	/**
	 * Clear all items out of our basket
	 */
	dCheckoutBasketClear();

	/**
	 * Clear our basket identity (ID + expiry)
	 */
	dCheckoutBasketIdentity(null, null);

	/**
	 * Clear applied discount codes
	 */
	dCheckoutBasketDiscounts([]);

	/**
	 * Clear applied order vouchers (gift vouchers) codes
	 */
	dCheckoutOrderVouchers([]);

};
