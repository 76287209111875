import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";

/**
 * Generic error view
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ErrorView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				singleColumn={true}
				textAlign={(!this.props.noCentre ? "center" : undefined)}>
				<String str="Error" component="span" variant="h6" bold={true} />
				<String color="textSecondary" str="We couldn't load this page." />
				<String color="textSecondary" str="Please try what you were doing again." />
				<String color="textSecondary" str="You might be offline if you keep seeing this." />
			</Container>
		);
	}

}

export default ErrorView;
