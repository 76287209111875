import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import ErrorView from "Views/ErrorView.js";
import dLoading from "Dispatchers/dLoading.js";
import {withRouter} from "react-router-dom";

/**
 * Error gate
 *
 * A generic error boundary gate.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ErrorGate extends React.PureComponent {

	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		dLoading(false);
	}


	/**
	 * Component updated.
	 *
	 * When the active route changes, we want to clear the error state.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.match !== this.props.match) {
			this.clear();
		}
	}


	/**
	 * Component unmounting.
	 *
	 * We want to clear the error state when rendered by Sentry.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		this.clear();
	}


	/**
	 * Try to clear the error state (effectively stop rendering ourselves).
	 * 
	 * When rendered by a Sentry error boundary, we can 
	 * call `resetError()` to clear the error state now.
	 *
	 * @return {void}
	 */
	clear() {
		if (this.props.resetError) {
			this.props.resetError();
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				alignContent="center"
				alignItems="center"
				autoRows="max-content"
				height={(!this.props.notFullHeight ? "100vh" : null)}
				justifyItems="center"
				px={this.constructor.p}
				pt={(!this.props.notFullHeight ? this.constructor.p : 0)}
				pb={this.constructor.p}
				singleColumn={true}
				textAlign="center"
				width="100vw">
				<ErrorView />
				<Container mt={0.5}>
					<Button
						label="Refresh"
						onClick={() => window.location.reload()}
						variant="outlined" />
				</Container>
			</Container>
		);
	}


	/**
	 * Padding
	 * 
	 * @type {Integer}
	 */
	static p = 2;

}

export default withRouter(ErrorGate);
