import React from "react";
import Card from "Components/Card.js";
import Container from "Components/Container.js";
import LoginForm from "./LoginForm.js";
import String from "Components/String.js";
import dLoading from "Dispatchers/dLoading.js";

/**
 * Login view
 *
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginView extends React.PureComponent {

	/**
	 * Mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		dLoading(false);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container columns="1fr" mb={1} px={1}>
				<Container
					columns="1fr"
					justifySelf="center"
					maxWidth="36rem"
					width="100%">
					<Card gap={2}>
						<Container>
							<String
								bold={true}
								centre={true}
								str={(this.props.header || "Members Area")}
								variant="h5" />
							<String
								centre={true}
								color="textSecondary"
								str={(this.props.caption || "Login to manage your account.")} />
						</Container>
						<LoginForm
							hopsAdminAuth={this.props.hopsAdminAuth}
							onAuth={this.props.onAuth} />
					</Card>
				</Container>
			</Container>
		);
	}

}

export default LoginView;
