import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import AuthService from "Services/AuthService.js";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import TextField from "Components/TextField.js";
import strings from "./PwcForm.strings.json";
import withMobile from "Hoc/withMobile.js";
import withSnack from "Hoc/withSnack.js";

/**
 * Password change form
 *
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class PwcForm extends AsyncStatefulComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Error code
		 * 
		 * @type {Integer|null}
		 */
		error: null,

		/**
		 * Loading?
		 *
		 * @type {Boolean}
		 */
		loading: false,

		/**
		 * Values
		 *
		 * @type {Object}
		 */
		values: {

			/**
			 * Current Password
			 *
			 * @type {String}
			 */
			current: "",

			/**
			 * New Password
			 *
			 * @type {String}
			 */
			next: "",

			/**
			 * New Password (Confirm)
			 *
			 * @type {String}
			 */
			nextConfirm: "",

			/**
			 * Security question 1 (answer)
			 *
			 * @type {String}
			 */
			sq1a: null,

			/**
			 * Security question 2 (answer)
			 *
			 * @type {String}
			 */
			sq2a: null,

			/**
			 * Security question 3 (answer)
			 *
			 * @type {String}
			 */
			sq3a: null

		}

	};

	/**
	 * Form ref.
	 * 
	 * @type {ReactRef}
	 */
	form = React.createRef();

	/**
	 * Current password input ref.
	 *
	 * Enables programmatic focusing.
	 *
	 * @type {ReactRef}
	 */
	currentInput = React.createRef();

	/**
	 * Next password input ref.
	 *
	 * Enables programmatic focusing.
	 *
	 * @type {ReactRef}
	 */
	nextInput = React.createRef();

	/**
	 * Security question input ref.
	 *
	 * Enables programmatic focusing.
	 *
	 * @type {ReactRef}
	 */
	sqInput = React.createRef();

	/**
	 * Value changed.
	 *
	 * @param {String} value
	 * @param {String} name
	 * @return {void}
	 */
	handleChange = (value, name) => {
		this.setState({
			error: false,
			values: {...this.state.values, [name]: value}
		});
	};

	/**
	 * Keypress handler.
	 *
	 * We submit on enter.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKey = e => {
		if (e.which === 13) {
			this.handleSubmit();
		}
	};

	/**
	 * Submitting.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleSubmit = e => {

		if (e) e.preventDefault();

		const {current, next, sq1a, sq2a, sq3a} = this.state.values;

		if (this.form?.current.reportValidity()) {

			this.setState({loading: true});

			AuthService.pwc(current, next, sq1a, sq2a, sq3a, this.props.token, this.props.authToken).then(() => {

				if (!this.props.disableResetAfterDone) {
					this.setState({
						values: {
							current: "",
							next: "",
							nextConfirm: "",
							sq1a: "",
							sq2a: "",
							sq3a: ""
						}
					});
				}
				this.props.snacks(strings.success);

				if (this.props.onDone) this.props.onDone();

			}).catch(e => {
				this.setState({loading: false});
				if (e?.response?.status === 400) {
					this.setState({error: 400});
					this.props.snacke(strings.e400);
				}
				else if (e?.response.status === 401) {
					this.setState({error: 401});
				}
				else if (e?.response.status === 406) {
					this.setState({error: 406});
				}
				else if (e?.response.status === 409) {
					this.setState({error: 409});
				}
				else if (this.props.onError) {
					this.props.onError(e);
				}
				else this.props.snacke(e);
			}).finally(() => {
				if (!this.props.disableEnableAfterDone) {
					this.setState({loading: false});
				}
			});

		}

	};


	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Provide a `ref`
		 *
		 * This is because we're wrapped with HOCs.
		 */
		if (props.innerRef) props.innerRef(this);

	}


	/**
	 * Component updated.
	 *
	 * We need to report state changes.
	 * 
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {

		if (prevState.values !== this.state.values) {
			if (this.props.onCanSubmitChange) {
				this.props.onCanSubmitChange(this.canSubmit);
			}
		}

		if (prevState.error !== this.state.error) {
			if ((this.state.error === 401) && this.currentInput.current) {
				setTimeout(() => this.currentInput.current.select());
			}
			else if ((this.state.error === 406) && this.sqInput.current) {
				setTimeout(() => this.sqInput.current.select());
			}
			else if (this.state.error && this.nextInput.current) {
				setTimeout(() => this.nextInput.current.select());
			}
		}

		if (prevState.loading !== this.state.loading) {
			if (this.props.onLoadingChange) {
				this.props.onLoadingChange(this.state.loading);
			}
		}

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex>
				<form
					onKeyDown={this.handleKey}
					onSubmit={this.handleSubmit}
					ref={this.form}>
					<Container columns={(!this.isMobile && "repeat(2, 1fr)")}>
						{this.renderFields()}
						{(Object.values((this.props.securityQuestions || {})).some(v => v) && this.renderSecurityQuestions())}
						{(this.props.withSubmit && this.renderSubmit())}
					</Container>
				</form>
				{(!this.props.noHelp && this.constructor.renderHelp())}
			</Flex>
		);
	}


	/**
	 * Render password entry fields.
	 * 
	 * @return {ReactNode}
	 */
	renderFields() {
		return (
			<Flex gap={(!this.isMobile ? 2 : (this.props.fieldsGap || 1.25))}>
				{(!this.props.noPasswordHeading && <String str="Password Details" />)}
				<Flex gap={this.props.fieldsGapInner}>
					{(!this.props.token && this.renderCurrent())}
					<TextField
						controlled={true}
						disabled={this.state.loading}
						error={(this.errorNext !== null)}
						helperText={(this.errorNext || (!this.isMobile ? " " : undefined))}
						inputProps={this.constructor.inputProps}
						inputRef={this.nextInput}
						label="New Password"
						name="next"
						onChange={this.handleChange}
						placeholderLabel={true}
						required={true}
						type="password"
						value={this.state.values.next}
						variant="outlined" />
					<TextField
						controlled={true}
						disabled={this.state.loading}
						error={this.isInvalidConfirmation}
						helperText={(this.isInvalidConfirmation ? strings.confirmMismatch : (!this.isMobile ? " " : undefined))}
						inputProps={this.constructor.inputProps}
						label="New Password (confirm)"
						name="nextConfirm"
						onChange={this.handleChange}
						placeholderLabel={true}
						required={true}
						type="password"
						value={this.state.values.nextConfirm}
						variant="outlined" />
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render the current password field.
	 * 
	 * @return {ReactNode}
	 */
	renderCurrent() {
		return (
			<TextField
				autoFocus={this.props.autoFocus}
				controlled={true}
				disabled={this.state.loading}
				error={(this.errorCurrent !== null)}
				helperText={(this.errorCurrent || (!this.isMobile ? " " : undefined))}
				inputRef={this.currentInput}
				label="Current Password"
				name="current"
				onChange={this.handleChange}
				placeholderLabel={true}
				required={true}
				type="password"
				value={this.state.values.current}
				variant="outlined" />
		);
	}


	/**
	 * Render security question fields.
	 * 
	 * @return {ReactNode}
	 */
	renderSecurityQuestions() {
		return (
			<Flex gap={(!this.isMobile ? 2 : (this.props.fieldsGap || 1.25))}>
				<String str="Security Questions" />
				<Flex gap={this.props.fieldsGapInner}>
					{
						Object.keys((this.props.securityQuestions || {})).map((i, key) => {

							const q = i.replace("Q", "");
							const sq = this.props.securityQuestions[i];

							if (!sq) return null;

							return (
								<TextField
									autoComplete="off"
									controlled={true}
									disabled={this.state.loading}
									error={(this.state.error === 406)}
									helperText={((this.state.error === 406) ? "Check your security question answers." : (!this.isMobile ? " " : undefined))}
									inputRef={((key === 0) ? this.sqInput : undefined)}
									key={key}
									label={sq.Text}
									name={`sq${q}a`}
									onChange={this.handleChange}
									placeholder={`Security Question ${(key + 1)}`}
									required={true}
									value={this.state.values[`sq${q}a`]}
									varchar={true}
									variant="outlined" />
							);

						})
					}
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render the submit button.
	 * 
	 * @return {ReactNode}
	 */
	renderSubmit() {
		return (
			<Button
				label="OK"
				loading={this.state.loading}
				type="submit" />
		);
	}


	/**
	 * Render help text.
	 * 
	 * @return {ReactNode}
	 */
	static renderHelp() {
		return (
			<String
				color="textSecondary"
				gap={0.5}
				str={strings.help}
				variant="body2" />
		);
	}


	/**
	 * Get error message for the current password field.
	 *
	 * @return {String|null}
	 */
	get errorCurrent() {
		return ((this.state.error === 401) ? strings.e401 : null);
	}


	/**
	 * Get error message for the new password field.
	 *
	 * @return {String|null}
	 */
	get errorNext() {
		if (this.isSamePassword) return strings.noReuse;
		else if (this.isInvalidConfirmation) return strings.confirmMismatch;
		else return null;
	}


	/**
	 * Get whether we can submit.
	 * 
	 * @return {Boolean}
	 */
	get canSubmit() {
		if (this.state.loading || this.isInvalidConfirmation || this.isSamePassword) return false;
		else return Object.values(this.state.values).every(v => v);
	}


	/**
	 * Get whether the new/new confirmation values match.
	 * 
	 * @return {Boolean}
	 */
	get isInvalidConfirmation() {
		const {next, nextConfirm} = this.state.values;
		return (!!((next && nextConfirm && (next !== nextConfirm))));
	}


	/**
	 * Get whether to use mobile view.
	 * 
	 * @return {Boolean}
	 */
	get isMobile() {
		return (this.props.isMobile || this.props.forceMobile);
	}


	/**
	 * Get whether the user seems to be reusing their current password.
	 * 
	 * @return {Boolean}
	 */
	get isSamePassword() {
		const {current, next} = this.state.values;
		return ((current && next && (current === next)) || (this.state.error === 409));
	}


	/**
	 * `inputProps`
	 * 
	 * @type {Object}
	 */
	static inputProps = {
		maxLength: 32,
		minLength: 8
	};

}

export default withMobile(withSnack(PwcForm));
