import React from "react";
import {useSnackbar} from "notistack";

let useSnackbarRef;

/**
 * Component that calls `setUseSnackbar()` on its `props`
 * to set up the `useSnackbarRef` with the Notistack `ref`
 */
const Notistack = props => {
	props.setUseSnackbar(useSnackbar());
	return null;
};

/**
 * Component that sets up `useSnackbarRef` when mounted
 */
export const SnackbarHost = () => <Notistack setUseSnackbar={useSnackbar => (useSnackbarRef = useSnackbar)} />;

/**
 * Close a snackbar once opened.
 *
 * @param {mixed} key
 * @return {void}
 */
export const closeSnack = key => useSnackbarRef.closeSnackbar(key);

/**
 * Show a snackbar.
 *
 * Messages are explicitly stringified.
 *
 * `Error` instances use the `error` variant unless overridden.
 *
 * When not specified, `preventDuplicate` is `true` for error snacks.
 *
 * @param {String} msg
 * @param {String} variant See `notistack`
 * @param {Boolean} optional `preventDuplicate` (`false`)
 * @param {Object} optional `options`
 * @return {void}
 */
export const snack = (msg, variant, preventDuplicate=false, options=null) => {
	if ((msg instanceof Error) && !variant) variant = "error";
	useSnackbarRef.enqueueSnackbar(`${msg}`, {variant, preventDuplicate, ...options});
};

export default snack;
