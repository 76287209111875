import History from "./History.js";
import dHistory from "Dispatchers/dHistory.js";

/**
 * Navigator
 *
 * This is used to handle navigation changes including current URI re-render.
 * 
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Navigator {

	/**
	 * Get current URI.
	 *
	 * @return {String}
	 */
	static getCurrentUri() {
		return `${History.location.pathname}${History.location.search}`;
	}


	/**
	 * Navigate to a new URI.
	 *
	 * This automatically handles reloading the current URI.
	 *
	 * @param {String} uri URI to navigate to
	 * @return {void}
	 */
	static navigate(uri) {
		dHistory();
		if (uri !== this.getCurrentUri()) {
			History.push(uri);
		}
		window.scrollTo({top: 0});
	}


	/**
	 * Reload the current URI.
	 * 
	 * @return {void}
	 */
	static reload() {
		this.navigate(this.getCurrentUri());
	}

}

export default Navigator;
