import React from "react";
import Bps from "Resources/Bps.json";
import mobile from "Helpers/Mobile.js";
import {withWidth} from "@material-ui/core";

/**
 * `withMobile()` HOC wrapper
 * 
 * Makes an `isMobile` prop available on components.
 * 
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withMobile = Component => {

	/**
	 * `ComponentWithMobile` inner wrapper
	 * 
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwm = class ComponentWithMobile extends React.PureComponent {

		/**
		 * Constructor.
		 *
		 * @param {Object} props
		 * @return {self}
		 */
		constructor(props) {
			super(props);

			/**
			 * Method binds
			 */
			this.bp = this.bp.bind(this);

		}

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					bp={this.bp}
					isMobile={mobile(this.props.width)}
					width={this.props.width} />
			);
		}


		/**
		 * Get whether we're at or above a given Material UI breakpoint name.
		 *
		 * @param {String} bp
		 * @return {Boolean}
		 */
		bp(bp) {
			return (Bps.indexOf(this.props.width) >= Bps.indexOf(bp));
		}

	};

	return withWidth()(cwm);

};

export default withMobile;
