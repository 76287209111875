import api from "api.js";
import Store from "App/Store.js";

/**
 * App service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AppService {

	/**
	 * Get registration details using our origin.
	 * 
	 * @return {Promise} Resolves with registration object
	 */
	static getRegistration() {
		return api.call({url: `/api/local`}).then(res => {
			return res.data;
		});
	}


	/**
	 * Get the logo URI for our current registration.
	 * 
	 * @return {String}
	 */
	static getRegistrationLogo() {
		const org = Store.getState().org;
		return `${api.base}/uploads/railway_logos/${org?.LogoTrade || org?.Logo}`;
	}


	/**
	 * Get the favicon URI for our current registration.
	 * 
	 * @return {String}
	 */
	static getRegistrationFaviconUri() {
		return `${api.base}/uploads/local/${Store.getState().org?.Id}/favicon.png`;
	}


	/**
	 * Resolve a path with an org's uploads directory root.
	 *
	 * @param {Integer} org ID
	 * @param {String} path optional
	 * @return {String}
	 */
	static getOrgUploadsPath(org, path="") {
		return `${api.base}/uploads/local/${org}${path}`;
	}

}

export default AppService;
