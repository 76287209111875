import React from "react";
import PropTypes from "prop-types";
import TextField from "Components/TextField.js";

const EmailAddressInput = props => {

	return (
		<TextField
			autoComplete={`${props.autoCompleteGroupingIdentifier ?? ""} email`}
			controlled={props.controlled}
			disabled={props.disabled}
			inputRef={props.inputRef}
			label={(props.label || "Email address")}
			name={props.name}
			onChange={props.onChange}
			onEnter={props.onEnter}
			placeholder={props.placeholder}
			required={props.required}
			size={props.size}
			type="email"
			value={props.value}
			varchar={true}
			variant={(props.variant || "outlined")} />
	);

};

EmailAddressInput.propTypes = {
	autoCompleteGroupingIdentifier: PropTypes.oneOf(["billing", "shipping"])
};

export default EmailAddressInput;
