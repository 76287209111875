import React from "react";
import Container from "Components/Container.js";
import Image from "Components/Image.js";
import String from "Components/String.js";
import scss from "./PoweredByHops.module.scss";
import Typography from "@material-ui/core/Typography";

export default ({color, gap, inlineChild, maxTextWidth, label, str}) => (
	<Container alignItems="flex-start" columns="1fr 4.5rem" gap={(gap || 4)}>
		<Container maxWidth={maxTextWidth}>
			<span>
				<Typography color={(color || "textSecondary")} display="inline">
					{label}
				</Typography>
				{(inlineChild && <span>&nbsp;</span>)}
				{inlineChild}
			</span>
			{(str && <String color={(color || "textSecondary")} str={str} />)}
		</Container>
		<a
			className={scss.logo}
			href="https://www.heritage-ops.org.uk"
			rel="noopener noreferrer"
			target="_blank"
			title="Visit HOPS">
			<Image
				alt="HOPS logo"
				noError={true}
				noLoader={true}
				objectFit="contain"
				src="/assets/hops.png" />
		</a>
	</Container>
);
