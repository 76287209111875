import Store from "App/Store.js";

export default reg => {
	Store.dispatch({
		type: "registration",
		org: {
			Id: reg.Org.Id,
			Name: reg.Org.Name,
			NameShort: reg.Org.NameShort,
			NameTrade: reg.Org.NameTrade,
			Logo: reg.Org.LogoTrade || reg.Org.Logo,
			GiftAidEnabled: reg.GiftAidEnabled,
			MarketingConsents: reg.MarketingConsents,
			PrivacyPolicyUrl: reg.PrivacyPolicyUrl,
			SavesDob: reg.Org.SavesDob,
			WebsiteUrl: reg.Org.WebsiteUrl,
			DeliveryMethods: reg.DeliveryMethods,
			AutomaticDiscounts: reg.AutomaticDiscounts
		},
		paymentMethods: (reg.PaymentMethods || []),
		strings: {
			index: reg.LandingText,
			checkout: reg.CheckoutText,
			checkoutSeatReservation: reg.CheckoutTextSeatReservation
		},
		theme: {
			AccentColour: reg.AccentColour,
			AccentColourSecondary: reg.AccentColourSecondary,
			FontFamily: reg.FontFamily,
			FontFamilyGoogle: reg.FontFamilyGoogle
		}
	});
};
