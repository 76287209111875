import React from "react";
import rem from "Helpers/Rem.js";
import {Box} from "@material-ui/core";

/**
 * Container component
 *
 * A generic grid container.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Container extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box {...this.forwards}	ref={this.props.rootRef} style={this.styles}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Get props to forward.
	 * 
	 * @return {Object}
	 */
	get forwards() {
		const forwards = {};
		this.constructor.propForwards.forEach(forward => {
			if (this.props.hasOwnProperty(forward)) {
				forwards[forward] = this.props[forward];
			}
		});
		return forwards;
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {

		const styles = {
			alignContent: this.props.alignContent,
			alignItems: this.props.alignItems,
			alignSelf: this.props.alignSelf,
			display: (this.props.display || "grid"),
			gap: rem(((this.props.gap !== undefined) ? this.props.gap : 1)),
			gridAutoColumns: this.props.gridAutoColumns,
			gridAutoFlow: (this.props.columnar ? "column" : "row"),
			gridAutoRows: (this.props.autoRows || "max-content"),
			gridTemplateColumns: (this.props.singleColumn ? "100%" : this.props.columns),
			gridTemplateRows: this.props.gridTemplateRows,
			height: this.props.height,
			justifyContent: this.props.justifyContent,
			justifyItems: this.props.justifyItems,
			justifySelf: this.props.justifySelf,
			maxWidth: this.props.maxWidth,
			minHeight: this.props.minHeight,
			order: this.props.order,
			overflow: this.props.overflow,
			textAlign: this.props.textAlign,
			width: (this.props.fullWidth ? "100%" : this.props.width),
			...this.props.style
		};

		if (this.props.gridRowGap) {
			styles.gridRowGap = rem(this.props.gridRowGap);
		}

		return styles;

	}


	/**
	 * Props to forward.
	 * 
	 * @type {Array}
	 */
	static propForwards = [
		"classes",
		"className",
		"component",
		"mb",
		"mt",
		"ml",
		"mr",
		"mx",
		"my",
		"pb",
		"pt",
		"px",
		"pr",
		"pl",
		"py"
	];

}

export default Container;
