import React from "react";
import AddedToBasketOverlay from "Checkout/AddedToBasketOverlay.js";
import AppService from "Services/AppService.js";
import BasketExpirationInterstitial from "Checkout/BasketExpirationInterstitial.js";
import ErrorGate from "Components/ErrorGate.js";
import Flex from "Components/Flex.js";
import Helmet from "react-helmet";
import SeatReservationDialog from "SeatReservation/SeatReservationDialog.js";
import SuspenseGate from "App/SuspenseGate.js";
import UiBanners from "./UiBanners.js";
import UiBar from "./UiBar.js";
import UiLoader from "./UiLoader.js";
import UiRouter from "./UiRouter.js";
import UiSuspense from "./UiSuspense.js";
import withMobile from "Hoc/withMobile.js";
import withOrg from "Hoc/withOrg.js";
import withTheme from "Hoc/withTheme.js";
import {connect} from "react-redux";
import * as Sentry from "@sentry/react";

/**
 * UI base
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Ui extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex gap={(!this.props.isMobile ? 2 : 1)}>
				<UiBar />
				<Flex
					alignItems="center"
					mt={(this.props.isMobile ? 8 : 12)}>
					<UiBanners />
					{(this.props.ready && this.renderMain())}
					<AddedToBasketOverlay />
					<BasketExpirationInterstitial />
					<SeatReservationDialog />
				</Flex>
				<UiLoader visible={this.props.loading} />
			</Flex>
		);
	}


	/**
	 * Render the main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Sentry.ErrorBoundary fallback={props => <ErrorGate notFullHeight={true} {...props} />}>
				<SuspenseGate>
					<React.Suspense fallback={<UiSuspense />}>
						<Helmet>
							<title>{this.props.org?.NameTrade}</title>
							<meta name="description" content={`Welcome to ${this.props.org?.NameTrade}!`} />
							<meta name="theme-color" content={(this.props.theme?.AccentColour || "#299c39")} />
							<link rel="shortcut icon" type="image/png" href={AppService.getRegistrationFaviconUri()} />
							<link rel="apple-touch-icon" href={AppService.getRegistrationFaviconUri()} />
						</Helmet>
						<UiRouter />
					</React.Suspense>
				</SuspenseGate>
			</Sentry.ErrorBoundary>
		);
	}

}

export default connect(({loading}) => ({loading}))(withMobile(withOrg(withTheme(Ui))));
