import React from "react";
import Banner from "Components/Banner.js";
import dSet from "Dispatchers/dSet.js";
import {withRouter} from "react-router-dom";

/**
 * Order complete banner
 * 
 * @package HOPS
 * @subpackage Checkout
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class OrderCompleteBanner extends React.PureComponent {

	/**
	 * Component updated.
	 *
	 * We hide ourselves by updating the app state after navigation.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			dSet({checkoutRedirect: false});
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Banner
				severity="success"
				title="We've received your order"
				str={["We'll send an order confirmation email to the address you entered during checkout.", "Please allow a few minutes for your email to arrive."]} />
		);
	}

}

export default withRouter(OrderCompleteBanner);
